import fetchProvider from "@src/data-access/role-provider";
import { getNhomDaoTao } from "@src/utils/common";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider: fetchProvider,
    storeName: "role",
    initStore: {
      listAllRole: [],
      listSelectTheoTaiKhoan: [],
      // role theo tk đăng nhập
      _PERMISSION: {},
    },
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = {
          ...state.role.params,
          ...payload,
          nhomDaoTao: getNhomDaoTao(),
        };
        dispatch.role.updateData({
          params: newParams,
        });

        return new Promise((resolve, reject) => {
          fetchProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.role.updateData({
                  listData: res.data,
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
      getListAllRole: (payload = {}, state) => {
        return new Promise((resolve, reject) => {
          fetchProvider
            .search({ page: 0, size: 99, ...payload })
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.role.updateData({
                  listAllRole: res.data.map((item) => ({
                    ...item,
                    label: item.ten,
                    value: item.id,
                  })),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
      getTheoTaiKhoan: (payload = {}, state) => {
        return new Promise((resolve, reject) => {
          fetchProvider
            .search({
              ...payload,
              nhomDaoTao: getNhomDaoTao(),
              page: 0,
              size: 100,
            })
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.role.updateData({
                  listTheoTaiKhoan: res.data,
                  listSelectTheoTaiKhoan: res.data.map((item) => ({
                    label: item.ten,
                    value: item.id,
                  })),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
    }),
  }),
};
