import Authorization from "@containers/Authorization";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ManageLayout from "./views/manage";
import SiteLayout from "./views/site";
import HomePage from "./views/site/home-page";

const App = ({ currentUser }) => {
  const routes = [
    {
      path: ["/manage"],
      component: ManageLayout,
    },
    {
      path: ["/auth"],
      component: SiteLayout,
    },
  ];
  // const dispatch = useDispatch();
  // dispatch({
  //   type: constants.action.action_user_login,
  //   value: dataCache.read("", "access"),
  // });

  return (
    <>
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={5000}
      ></ToastContainer>
      <BrowserRouter>
        <Switch>
          {routes.map((route, key) => {
            if (route.component) {
              return (
                <Authorization
                  key={key}
                  path={route.path}
                  render={(props, auth) => <route.component {...props} />}
                />
              );
            }
            return null;
          })}
          <Route path="/home-page" render={(props) => <HomePage />} />
          <Redirect to={currentUser?.id ? "/manage" : "/auth/login"} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default connect(({ auth: { currentUser } }) => ({ currentUser }))(App);
