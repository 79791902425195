import API from "@variables/api";
import clientUtils from "../utils/client-utils";
import constants from "../utils/const";
import baseProvider from "./base-provider";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  ...baseProvider({ url: API.course }),
  getById(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.course + "/" + id, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  create(body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.course, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  update(body, id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.course + "/" + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateFile(body, id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.course + "/update-file/" + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateFileName(courseId, body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi(
          "put",
          constants.api.course + "/update-file-name/" + courseId,
          body
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("delete", constants.api.course + "/" + id, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
