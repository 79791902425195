import clientUtils from "@src/utils/client-utils";
import API from "@variables/api";
import baseProvider from "./base-provider";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  ...baseProvider({ url: API.hoSo }),
  import: ({ file, dong, sheet }) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("dong", dong);
      formData.append("sheet", sheet);

      const func = (x) => {
        resolve(x);
      };
      clientUtils.upload("post", API.hoSo + "/import", formData, func, func);
    });
  },
};
