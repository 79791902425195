import { Collapse } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

const LinkSideBar = ({
  path,
  name,
  showCollapse,
  setShowCollapse = () => {},
  iconSvg,
  icon,
  className,
  hasChild,
  childrens = [],
  ChildRight,
}) => (
  <Link
    onClick={() => setShowCollapse(!showCollapse)}
    to={path}
    title={name}
    className={
      childrens.filter((item) => item.path === window.location.pathname)
        .length > 0 || path === window.location.pathname
        ? "active item-sidebar-link"
        : "item-sidebar-link"
    }
  >
    {iconSvg ? iconSvg : <i className={icon}></i>}
    {/* {item?.span && <item.span></item.span>} */}
    <span className="nav-link-text font-weight-bold">{name}</span>
    {hasChild &&
      (showCollapse ? (
        <i className="ni ni-bold-down ni-dropdown" />
      ) : (
        <i className="ni ni-bold-right ni-dropdown" />
      ))}
    {ChildRight && (
      <div className="right-child">
        <ChildRight />
      </div>
    )}
  </Link>
);

function ItemMenu({ item, totalChange, totalNoti }) {
  const [showCollapse, setShowCollapse] = useState(false);
  const hasChild = item.childrens?.length > 0;
  return (
    <div className="item-sidebar">
      {!hasChild ? (
        <LinkSideBar {...item} />
      ) : (
        <Collapse bordered={false} expandIconPosition={"right"}>
          <Panel
            header={
              <LinkSideBar
                {...item}
                hasChild={hasChild}
                showCollapse={showCollapse}
                setShowCollapse={setShowCollapse}
              />
            }
          >
            {item.childrens.map((child, index) => (
              <LinkSideBar key={index} {...child} />
            ))}
          </Panel>
        </Collapse>
      )}
    </div>
  );
}

export default ItemMenu;
