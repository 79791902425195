// import Circle from "@assets/img/icons/common/circle.png";
import Circle from "@assets/img/icons/common/Vector.png";
import ModalChangePassword from "@site/changePassword";
import { prefix } from "@src/utils/const";
import clientUtil from "@utils/client-utils";
import { Dropdown, Menu, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { currentPage } from "../SideBar/constant";
import { Head, ItemMenu } from "./styled";

const HeadPage = ({
  nhomHienTai,
  currentUser,
  updateData,
  getRole,
  getTheoTaiKhoan,
  getCourse,
  listnhomDaoTao,
  getUtils,
  _PERMISSION,
  nhomDaoTao,
}) => {
  const refChangePass = useRef();
  const [state, setState] = useState({ showTooltip: false });
  const history = useHistory();

  useEffect(() => {
    getUtils({ name: "nhomDaoTao" });
  }, []);
  const handleLogout = () => {
    // localStorage.removeItem("_access");
    localStorage.removeItem("_AUTH");
    window.location.href = "/login";
  };

  const menu = (
    <Menu>
      <ItemMenu>
        <Link to={"/manage/user-profile"}>
          <i className="ni ni-single-02" />
          <span>Thông tin cá nhân</span>
        </Link>
      </ItemMenu>
      <ItemMenu>
        <div
          onClick={() => {
            refChangePass.current && refChangePass.current.show();
          }}
          // to={
          //   currentUser.role === constants.role.admin
          //     ? "/admin/user-profile"
          //     : currentUser.role === constants.role.teacher
          //     ? "/teacher/user-profile"
          //     : "/student/user-profile"
          // }
        >
          <i className="ni ni-settings-gear-65" />
          <span>Đổi mật khẩu</span>
        </div>
      </ItemMenu>
      <Menu.Divider style={{ height: "2px" }} />
      <ItemMenu>
        <Link to="#" onClick={(e) => handleLogout()}>
          <i className="ni ni-user-run" />
          <span>Đăng xuất</span>
        </Link>
      </ItemMenu>
    </Menu>
  );
  // useEffect(() => {
  //   // const url = window.location.pathname;
  //   setUrl(window.location.pathname);
  // }, [window.location.pathname]);
  const url = window.location.pathname;
  // setUrl(window.location.pathname)
  return (
    <Head>
      <div style={{ width: url === "/home-page" ? "80%" : "" }}>
        <Breadcrumb style={{ marginLeft: url === "/home-page" ? "40%" : "" }} />
      </div>
      <div className="header-content-right">
        <div className="dropdown-box">
          <div className="dropdown-img">
            <img alt="" src={Circle} />
          </div>
          {_PERMISSION.ROLE_ADMIN ? (
            <>
              <div className="dropdown-title">Nhóm đào tạo</div>
              <div className="dropdown-list">
                <Select
                  onSelect={(nhomHienTai, nhom) => {
                    updateData({ nhomHienTai });
                    const hrefTo = currentPage(nhomHienTai);
                    history.push(hrefTo);
                    if (hrefTo === "/manage/account-group") {
                      getRole();
                    } else if (hrefTo === "/manage/course") {
                      getCourse({
                        page: 0,
                        size: 10,
                        status: 1,
                        nhomDaoTao: nhomHienTai,
                      });
                    } else if (hrefTo === "/manage/account") {
                      getTheoTaiKhoan();
                    }
                  }}
                  bordered={false}
                  value={nhomHienTai}
                  options={listnhomDaoTao}
                ></Select>
              </div>
            </>
          ) : (
            <div className="dropdown-title">
              {listnhomDaoTao.find((item) => item.value === nhomDaoTao)?.name}
            </div>
          )}
        </div>
        {/* <Notifications className="header-notifications" /> */}
        <div className="box-user-info">
          <div className="name">
            <div className="fullname">{currentUser.full_name}</div>
            <div className="username">{currentUser.username}</div>
          </div>
          <div>
            <div>
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <img
                  alt=""
                  onClick={() => setState({ showTooltip: !state.showTooltip })}
                  className="avatar pointer"
                  src={
                    clientUtil.serverApi +
                    prefix +
                    "/files/" +
                    currentUser.avatar
                  }
                  width="60px"
                />
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassword ref={refChangePass} />
    </Head>
  );
};

export default connect(
  ({
    auth: { currentUser = {} },
    global: { nhomHienTai },
    utils: { listnhomDaoTao = [] },
  }) => ({
    currentUser,
    _PERMISSION: currentUser?._PERMISSION || {},
    nhomDaoTao: currentUser?.nhomDaoTao,
    nhomHienTai,
    listnhomDaoTao,
  }),
  ({
    course: { search: getCourse },
    role: { search: getRole, getTheoTaiKhoan },
    global: { updateData },
    utils: { getUtils },
  }) => ({
    getCourse,
    getRole,
    getTheoTaiKhoan,
    updateData,
    getUtils,
  })
)(HeadPage);
