import userProvider from "@data-access/user";
import roleProvider from "@src/data-access/role-provider";
import { getNhomDaoTao } from "@src/utils/common";

export default {
  state: {
    listUser: [],
    listUserSelect: [],
    totalElements: 0,
    listStudent: [],
    totalStudent: 0,
    listAllUser: [],
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    getData: (payload, state) => {
      userProvider.search(payload).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.user.updateData({
            listUser: res.data,
            totalElements: res.totalElements,
          });
        }
      });
    },
    getListStudent: (payload, state) => {
      userProvider.search({ ...payload }).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.user.updateData({
            listStudent: res.data,
            totalStudent: res.totalElements,
          });
        }
      });
    },
    getUser: (
      { loaiTaiKhoan, nhomDaoTao, baiGiangId } = {},
      { user: { listAllUser } }
    ) => {
      return new Promise((resolve, reject) => {
        if (listAllUser.length > 0) {
          const newUsers = listAllUser.filter(
            (item) =>
              item.dsBaiGiangId?.some((bg) => bg === baiGiangId) || !baiGiangId
          );
          dispatch.user.updateData({
            listUser: newUsers,
            oldLoaiTaiKhoan: loaiTaiKhoan,
            oldNhomDaoTao: nhomDaoTao,
          });
          resolve(newUsers);
          return;
        }
        userProvider
          .search({ page: 0, size: 999, loaiTaiKhoan, nhomDaoTao })
          .then((res) => {
            if (res && res.code === 0) {
              dispatch.user.updateData({
                listUser: res.data,
                listUserSelect: res.data.map((item) => ({
                  label: item.fullName,
                  value: item.id,
                })),
                listAllUser: res.data,
                oldLoaiTaiKhoan: loaiTaiKhoan,
                oldNhomDaoTao: nhomDaoTao,
              });
            }
          });
      });
    },
  }),
};
