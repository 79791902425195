import fetchProvider from "@src/data-access/ngay-truc-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider: fetchProvider,
    storeName: "ngayTruc",
    initStore: {
      // role theo tk đăng nhập
      _PERMISSION: {},
    },
  }),
};
