import registerCourseProvider from "@data-access/register-course";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider: registerCourseProvider,
    storeName: "register_course",
    initStore: { listRegister: [], totalElements: 0 },
    customEffect: ({ dispatch }) => ({
      getData: (payload, state) => {
        registerCourseProvider.search(payload).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.register_course.updateData({
              listRegister: res.data,
              totalElements: res.totalElements,
            });
          }
        });
      },
    }),
  }),
};
