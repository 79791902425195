import styled from "styled-components";

export const Button = styled.button`
  display: inline-block;
  font-weight: 400;

  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  margin-right: 5px;
  font-size: 14px;
  height: 32px;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
  border: 1px solid rgba(0, 0, 0, 0.103176);

  .custom-btn {
    display: flex;
    .custom-icon-loading {
      transition: all 0.4s;
      margin-top: -4px;
      margin-left: 12px;
      .anticon-loading {
        font-size: 18px;
        margin-left: -5px;
      }
    }
    &-content {
      transition: all 0.4s;
    }
    &-icon {
      padding-top: 3px;
      margin-right: -10px;
      opacity: 0;
      transition: all 0.8s;
    }
    &:hover {
      .custom-icon-loading {
        margin-left: 7px;
      }
      .custom-btn-content {
        margin-right: 5px;
      }
      .custom-btn-icon {
        margin-right: 0;
        opacity: 1;
      }
    }
  }
  &.custom-btn {
    &-loading {
      opacity: 0.8;
    }
    &-disabled {
      background-color: #ccc !important;
    }
  }

  &.btn-white {
    background-color: white;
    border: 1px solid #bbb;
    color: black;
    &:hover {
      border: 1px solid #999;
      color: #999;
    }
  }
  &.btn-pink {
    background-color: #ef5da8;
    color: white;
    &:hover {
      background-color: #de4c97;
    }
  }
  &.btn-blue {
    background-color: var(--blue);
    color: white;
    &:hover {
      background-color: var(--blue-7);
    }
  }
`;
