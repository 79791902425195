import Authorization from "@src/containers/Authorization";
import { Spin } from "antd";
import React, { useEffect, useRef } from "react";
import { Switch, useLocation } from "react-router-dom";
import ChangePass from "./changePassword";
import Login from "./login";
import Register from "./register";
import Loadable from "react-loadable";

function Loading() {
  return (
    <div style={{ height: 400, paddingTop: 50 }}>
      <Spin>Loading</Spin>
    </div>
  );
}

const SiteLayout = (props) => {
  const routes = [
    {
      path: ["/", "/login", "/auth/login"],
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      layout: "/auth",
    },
    {
      path: "/auth/register",
      name: "Register",
      icon: "ni ni-circle-08 text-pink",
      component: Register,
      layout: "/auth",
    },
    {
      path: "/auth/change-password",
      name: "Đổi mật khẩu",
      icon: "ni ni-circle-08 text-pink",
      component: ChangePass,
      layout: "/auth",
    },
    {
      path: "/auth/maintain",
      name: "Thông báo bảo trì",
      icon: "ni ni-circle-08 text-pink",
      component: Loadable({
        loader: () => import("./maintain-notification"),
        loading: Loading,
      }),
    },
  ];
  const mainContent = useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <div className="main-content" ref={mainContent}>
      <Switch>
        {routes.map((route, key) => {
          if (route.component) {
            return (
              <Authorization
                exact
                path={route.path}
                key={key}
                render={(props, auth) => <route.component {...props} />}
              />
            );
          }
        })}
      </Switch>
    </div>
  );
};

export default SiteLayout;
