import clientUtils from "../utils/client-utils";
import constants from "../utils/const";

export default {
  search: ({ page, size, ...search }) => {
    let searchString = "";
    Object.keys(search).forEach((key) => {
      if (search[key]) searchString = `${searchString}&${key}=${search[key]}`;
    });
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi(
          "get",
          `${constants.api.roles}?page=${page}&size=${size}${searchString}`,
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  searchCurrentRole: ({ page, size, ...search }) => {
    let searchString = "";
    Object.keys(search).forEach((key) => {
      if (search[key]) searchString = `${searchString}&${key}=${search[key]}`;
    });
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi(
          "get",
          `${constants.api.roles}?${searchString}`,
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  create(body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.roles, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  update(body, id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.roles + "/" + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("delete", constants.api.roles + "/" + id, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
