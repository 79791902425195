import programProvider from "@data-access/program";
import courseProvider from "@data-access/course";
import subjectProvider from "@data-access/subject";
import scheduleProvider from "@data-access/schedule";
import moment from "moment";

const fillterAllSchedule = (schedules) => {
  const allSchedule = [
    ...(schedules.listSchedule || []),
    ...(schedules.teacherSchedules || []),
    ...(schedules.placeSchedules || []),
  ];

  const set = new Set();
  allSchedule.forEach((item) =>
    set.add(item.day + "_" + item.startAt + "_" + item.endAt)
  );
  return Array.from(set).map((key) =>
    allSchedule.find((e) => {
      const b = e.day + "_" + e.startAt + "_" + e.endAt;
      return key === b;
    })
  );
};

const mergeRowSchedule = (schedule) => {
  const maps = {};
  // gộp các hàng có cùng thứ + cùng môn + cùng giảng viên + cùng địa điểm lại thành 1 hàng
  schedule.forEach((item) => {
    const key =
      item.subjectId +
      "_" +
      item.teacherId +
      "_" +
      item.placeId +
      "_" +
      item.startAt +
      "_" +
      item.endAt +
      "_" +
      moment(item.day).format("dddd");

    if (maps[key]) {
      maps[key] = {
        ...maps[key],
        days: [{ id: item.id, day: item.day }, ...maps[key].days],
      };
    } else {
      const thu = moment(item.day).format("dddd");
      maps[key] = {
        ...item,
        thu,
        days: [{ id: item.id, day: item.day }],
      };
    }
  });
  return maps;
};

export default {
  state: {
    listData: [],
    totalElements: 0,
    listScheduleWeek: [],
    listSchedule: [],
    listSubject: [],
    selectId: -1,
    listScheduleCalendar: [],
    selectCourse: {},
    courseId: -1,
    programId: -1,
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    getData: (payload, state) => {
      scheduleProvider.search(payload).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.schedule.updateData({
            listData: res.data,
            totalElements: res.totalElements,
          });
        }
      });
    },
    getCourse: ({ status, tenKhoa, maTenChuongTrinh } = {}) => {
      courseProvider
        .search({
          page: 0,
          size: 999,
          tenKhoa,
          maTenChuongTrinh,
          nhomDaoTao: 10,
          active: true,
        })
        .then((res) => {
          if (res && res.code === 0) {
            if (status === 1) {
              dispatch.schedule.updateData({
                listCoursePlan: res.data,
              });
            } else if (status === 2) {
              dispatch.schedule.updateData({
                listCourseStudy: res.data,
              });
            } else {
              dispatch.schedule.updateData({
                listCoursePlan: res.data.filter((item) => item.status === 1),
                listCourseStudy: res.data.filter((item) => item.status === 2),
              });
            }
          }
        });
    },
    getSchedule: (param = {}, state) => {
      const { selectCourse } = state.schedule;

      scheduleProvider
        .search({
          ...param,
          size: 999999,
          tuNgay: moment(selectCourse.ngayKhaiGiang).format("YYYY-MM-DD"),
          denNgay: moment(selectCourse.ngayKetThuc).format("YYYY-MM-DD"),
        })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            const newSchedule = Object.assign([], state.schedule);
            if (param.teacherId)
              newSchedule.teacherSchedules = res.data.map((item) => ({
                ...item,
                scheduleFor: 2,
              }));
            if (param.placeId)
              newSchedule.placeSchedules = res.data.map((item) => ({
                ...item,
                scheduleFor: 3,
              }));
            newSchedule.listScheduleCalendar = fillterAllSchedule(newSchedule);

            dispatch.schedule.updateData({
              ...newSchedule,
            });
            // setSchedules(newSchedule);
            // set dữ liệu hiển thị lịch bên trái
            // setScheduleSource(newSchedule.listAllSchedule);
          }
        });
    },
    onSelectCourse: (payload, state) => {
      let { courseId, programId, selectCourseId, selectCourse, callback } =
        payload || state.schedule;
      if (!selectCourse) {
        selectCourse = state.schedule.selectCourse;
      }
      if (!selectCourseId) {
        selectCourseId = state.schedule.selectCourseId;
      }

      const handleDataSchedule = (schedule = [], subject = []) => {
        const maps = mergeRowSchedule(schedule);
        dispatch.schedule.updateData({
          listScheduleWeek: Object.keys(maps).map((item) => maps[item]),
          listSchedule: schedule,
          listSubject: subject.map((item) => ({
            ...item,
            hasScheduled: schedule.some((sd) => sd.subjectId === item.id),
          })),
          listScheduleCalendar: schedule,
          selectCourseId,
          selectCourse,
          courseId,
          programId,
        });
        if (callback) callback();
      };

      Promise.all([
        scheduleProvider.search({
          courseId,
          size: 999,
        }),
        subjectProvider.search({ programId, size: 999 }),
      ]).then((res) => {
        handleDataSchedule(res[0]?.data, res[1]?.data);
      });
    },
    getTimetable: (payload, state) => {
      const { selectCourse } = state.schedule;

      scheduleProvider
        .search({
          ...payload,
          size: 999999,
        })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            const maps = mergeRowSchedule(res.data);
            dispatch.schedule.updateData({
              listScheduleWeek: Object.keys(maps).map((item) => maps[item]),
            });
          }
        });
    },
  }),
};
