import roleProvider from "@src/data-access/role-provider";
import { getNhomDaoTao } from "@src/utils/common";
import cacheProvider from "@data-access/data-cache";

const initState = () => {
  const currentUser = cacheProvider.read("", "AUTH");
  
  return {
    nhomHienTai: currentUser?.nhomDaoTao || 10,
    tenNhomHienTai: "Đào tạo liên tục",
    roleId: "",
  };
};

export default {
  state: initState(),
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    getRoleId: ({ loaiTaiKhoan, nhomHienTai } = {}, state) => {
      return new Promise((resolve, reject) => {
        roleProvider
          .filter({
            nhomDaoTao: nhomHienTai || getNhomDaoTao(),
            loaiTaiKhoan,
          })
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch.global.updateData({
                roleId: res.data?.id,
                roleData: res.data,
              });
            }
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
  }),
};
