import clientUtils from "@src/utils/client-utils";
import API from "@variables/api";
import baseProvider from "./base-provider";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  ...baseProvider({ url: API.dinhMucHoTro }),
  saveAll(body, id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", `${API.dinhMucHoTro}/all/${id}`, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
