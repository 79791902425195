import { Collapse } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarContent = styled.nav`
  font-size: 15px;
  // display: block;
  // position: fixed;
  // top: 0;
  // bottom: 0;
  // width: 100%;
  // max-width: 250px;
  .item-sidebar {
    a {
      display: flex;
      color: #888;
      font-weight: 600;
    }
    .item-sidebar-link {
      padding: 0.65rem 1.5rem;
      .right-child {
        margin-left: auto;
      }
      i {
        min-width: 2.25rem;
        font-size: 0.9375rem;
        line-height: 1.5rem;
      }
      &.active {
        background-color: var(--blue-1);
        color: var(--blue-9);
        border-left: 2px solid var(--blue-8);
        box-shadow: -2px 2px 8px #ddd;
        transform: translateY(-2px);
      }
      &:hover {
        box-shadow: -2px 2px 8px #ddd;
        transform: translateY(-2px);
      }
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
    .ant-collapse-item {
      background: white;
      border-bottom: none;
      .ant-collapse-header {
        padding: 0;
        display: block;
        .anticon {
          display: none;
        }
      }
      .ant-collapse-content {
        padding-left: 20px;
      }
    }
  }
`;

const { Panel } = Collapse;

const LinkSideBar = ({
  path,
  name,
  showCollapse,
  setShowCollapse = () => {},
  iconSvg,
  icon,
  className,
  hasChild,
  childrens = [],
}) => (
  <Link
    onClick={() => setShowCollapse(!showCollapse)}
    to={path}
    title={name}
    className={
      childrens.filter((item) => item.path === window.location.pathname)
        .length > 0 || path === window.location.pathname
        ? "active item-sidebar-link"
        : "item-sidebar-link"
    }
  >
    {iconSvg ? iconSvg : <i className={icon}></i>}
    {/* {item?.span && <item.span></item.span>} */}
    <span className="nav-link-text font-weight-bold">{name}</span>
    {hasChild &&
      (showCollapse ? (
        <i className="ni ni-bold-down ni-dropdown" />
      ) : (
        <i className="ni ni-bold-right ni-dropdown" />
      ))}
  </Link>
);

function ItemMenu({ item, key, totalChange, totalNoti }) {
  const [showCollapse, setShowCollapse] = useState(false);
  const hasChild = item.childrens?.length > 0;
  return (
    <div className="item-sidebar">
      {!hasChild ? (
        <LinkSideBar {...item} />
      ) : (
        <Collapse bordered={false} expandIconPosition={"right"}>
          <Panel
            header={
              <LinkSideBar
                {...item}
                hasChild={hasChild}
                showCollapse={showCollapse}
                setShowCollapse={setShowCollapse}
              />
            }
            key={key}
          >
            {item.childrens.map((child) => (
              <LinkSideBar {...child} />
            ))}
          </Panel>
        </Collapse>
      )}
    </div>
  );
}

export default ItemMenu;
