import clientUtils from "../utils/client-utils";
import constants from "../utils/const";
import strings from "../utils/strings";
import { combineUrlParams } from "@utils/common";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getBody(data) {
    const {
      id,
      day,
      placeId,
      subjectId,
      teacherId,
      courseId,
      kipHoc,
      changeScheduleId,
      dates,
      status,
      reason,
      startAt,
      endAt,
      hinhThucGiangDay,
      troGiangId,
      noiDungBaiGiang,
      soTiet,
    } = data;
    return {
      id,
      day,
      placeId,
      subjectId,
      teacherId,
      courseId,
      changeScheduleId,
      kipHoc,
      status,
      reason,
      startAt,
      endAt,
      hinhThucGiangDay,
      troGiangId,
      noiDungBaiGiang,
      soTiet,
    };
  },
  search(param) {
    const url = combineUrlParams(constants.api.schedule, {
      ...param,
      page: param.page || 0,
      size: param.size || 10,
    });

    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", url, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getSchedule() {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.schedule + "/get", {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getClass(param) {
    return new Promise((resolve, reject) => {
      const url = combineUrlParams(constants.api.schedule + "/class", param);
      clientUtils
        .requestApi("get", url, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createAll(data) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.schedule + "/batch", data)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.schedule, data)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  update(data, id) {
    const body = this.getBody(data);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.schedule + "/" + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  delete(ids) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("delete", constants.api.schedule + "/" + ids, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  handleChangeInfo(id, data) {
    const body = {
      ...this.getBody(data),
      deleteId: data.id,
      changeScheduleId: undefined,
    };
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi(
          "put",
          constants.api.schedule + "/handle-change-schedule/" + id,
          body
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  countChange() {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", strings.api.schedule.COUNT_CHANGE_SCHEDULE, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  findAllChange() {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", strings.api.schedule.FIND_ALL_CHANGE, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  changeSchedule(id, data) {
    const body = this.getBody(data);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", strings.api.schedule.CHANGE_SCHEDULE + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
