import { Button, Checkbox, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { WrapperStyledLogin } from "../styled";
// import { toast } from "react-toastify";

const Login = ({ onLogin, history }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleLogin(e);
    }
  };

  const handleLogin = () => {
    onLogin({ username, password });
  };

  return (
    <WrapperStyledLogin>
      <div className="form-login">
        <Row className="group-tab">
          <div className="btn-tab btn-tab_active">
            <img src={require("@assets/images/login-active.png").default} />
            <span>Đăng nhập</span>
          </div>
          <div
            className="btn-tab"
            onClick={() => history.push("/auth/register")}
          >
            <img src={require("@assets/images/register.png").default} />
            <span>Đăng ký</span>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/account.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Tài khoản"
                value={username}
                name="username"
                type="text"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleEnter(e)}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/key.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Mật khẩu"
                value={password}
                type="password"
                name="password"
                autoComplete="new-password"
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleEnter(e)}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="btn-submit mb-0 pointer" onClick={handleLogin}>
            Đăng nhập
          </div>
        </Row>

        <Row className="group-input group-checkbox">
          <Checkbox>Nhớ mật khẩu</Checkbox>
          <div className="ant-checkbox-wrapper">Quên mật khẩu?</div>
        </Row>
        <Row className="group-input">
          <div className="child footer-form">
            <div>Chưa có tài khoản?</div>
            <div
              className="register"
              onClick={() => history.push("/auth/register")}
            >
              Đăng ký
            </div>
          </div>
        </Row>
      </div>
    </WrapperStyledLogin>
  );
};

export default connect(
  ({}) => ({}),
  ({ auth: { onLogin } }) => ({
    onLogin,
  })
)(Login);
