import clientUtils from "../utils/client-utils";
import constants from "../utils/const";
import baseAccess from "./base-provider";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  ...baseAccess({url: constants.api.registerCourse}),
  // search(param) {
  //   const parameters =
  //     (param.page ? "?page=" + param.page : "?page=0") +
  //     (param.size ? "&size=" + param.size : "&size=10") +
  //     (param.courseId ? "&courseId=" + param.courseId : "") +
  //     (param.semester ? "&semester=" + param.semester : "") +
  //     (param.studentId ? "&studentId=" + param.studentId : "") +
  //     (param.fullName ? "&fullName=" + param.fullName : "");

  //   return new Promise((resolve, reject) => {
  //     clientUtils
  //       .requestApi("get", constants.api.registerCourse + parameters, {})
  //       .then((x) => {
  //         resolve(x);
  //       })
  //       .catch((e) => {
  //         reject(e);
  //       });
  //   });
  // },
  register(body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.registerCourse, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  currentCourse() {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.registerCourse + "/current-course", {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getSemesters(param) {
    // student see result
    const parameters = param.courseId ? "?courseId=" + param.courseId : "";
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi(
          "get",
          constants.api.registerCourse + "/get-list-semester" + parameters,
          {}
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  batch(body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.registerCourse + "/batch", body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  putBatch(body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.registerCourse + "/batch", body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateFile(body, id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.registerCourse + "/update-file/" + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateFileName(registerId, body) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi(
          "put",
          constants.api.registerCourse + "/update-file-name/" + registerId,
          body
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
