import fetchProvider from "@data-access/hoc-ham-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "hocHam",
    initStore: {},
  }),
};
