const { UrlServer } = require("./client-utils");

const prefix = "/api/medical-education/v1";
const domain = UrlServer();

module.exports = {
  user: {
    login: "/user/login",
  },
  file: domain + prefix + "/files/",
  image: domain + "/images/",
  uploadImage: domain + "/user/upload-avatar",
  downloadTemplate: domain + "/uploads/template.xlsx",
};
