import utilProvider from "@data-access/util-provider";

export default {
  state: {},
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    getUtils: ({ name }) => {
      const data = localStorage.getItem(`UTILS__${name}`);
      if (data) {
        dispatch.utils.updateData({
          [`list${name}`]: JSON.parse(data).map((item) => ({
            ...item,
            label: item.name,
          })),
        });
        return;
      }
      utilProvider.getUtils({ name }).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.utils.updateData({
            [`list${name}`]: res.data.map((item) => ({
              ...item,
              label: item.name,
            })),
          });
          localStorage.setItem(`UTILS__${name}`, JSON.stringify(res.data));
        }
      });
    },
  }),
};
