import placeProvider from "@data-access/place-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider: placeProvider,
    storeName: "place",
    initStore: {
      listAllProgram: [],
    },
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = { ...state.place.params, ...payload };
        dispatch.place.updateData({
          params: newParams,
        });

        return new Promise((resolve, reject) => {
          placeProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.place.updateData({
                  listData: res.data,
                  listDataSelect: res.data.map((item) => ({
                    label: item.address,
                    value: item.id,
                  })),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
    }),
  }),
};
