import "@fortawesome/fontawesome-free/css/all.min.css";
import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./assets/css/argon-dashboard-react.min.css";
import "./assets/plugins/nucleo/css/nucleo.css";
import "./assets/vendor/fullcalendar.min.css";
import store from "./redux";
import "./style.scss";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
