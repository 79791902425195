import fetchProvider from "@data-access/don-vi-ho-tro-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "donViHoTro",
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = { ...state.donViHoTro.params, ...payload };
        dispatch.donViHoTro.updateData({
          params: newParams,
        });

        return new Promise((resolve, reject) => {
          fetchProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.donViHoTro.updateData({
                  listData: res.data,
                  listDataSelect: res.data.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.ten,
                  })),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
    }),
  }),
};
