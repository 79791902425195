import { toast } from "react-toastify";
import moment from "moment";

export default ({
  storeName,
  fetchProvider,
  initStore = {
    listData: [],
    listAllData: [],
  },
  customEffect = () => ({}),
}) => ({
  state: {
    listData: [],
    totalElements: 0,
    params: { page: 0, size: 10 },
    ...initStore,
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    search: (payload = {}, state) => {
      const newParams = { ...state[storeName].params, ...payload };
      dispatch[storeName].updateData({
        params: newParams,
      });

      return new Promise((resolve, reject) => {
        fetchProvider
          .search(newParams)
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch[storeName].updateData({
                listData: res.data,
                totalElements: res.totalElements,
              });
            }
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    // payload có id là put còn không thì là post
    save: (payload) => {
      const callApi = payload.id ? fetchProvider.put : fetchProvider.post;
      return new Promise((resolve, reject) => {
        callApi(payload, payload.id)
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch[storeName].search();
            } else if (res && res.code === 401) {
              window.location.href = "/login";
            } else {
              toast.error(res.message);
            }
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    patch: (payload) => {
      const callApi = payload?.id ? fetchProvider.patch : fetchProvider.post;
      const id = payload?.id;
      delete payload.id;
      return new Promise((resolve, reject) => {
        callApi(payload, id)
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch[storeName].search();
            } else if (res && res.code === 401) {
              window.location.href = "/login";
            } else {
              toast.error(res.message);
            }
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    delete: (payload, state) => {
      console.log("deleta", payload);
      return new Promise((resolve, reject) => {
        fetchProvider
          .delete(payload)
          .then((res) => {
            if (res && res.code === 0) {
              dispatch[storeName].search();
            } else if (res && res.code === 401) {
              window.location.href = "/login";
            } else {
              toast.error(res.message);
            }
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    getAll: () => {
      // const rawData = localStorage.getItem(`LIST_ALL__${storeName}`);
      // if (rawData) {
      //   const { time, data } = JSON.parse(rawData);
      //   if (moment().minute() - moment(time).minute() < 5) {
      //     dispatch[storeName].updateData({
      //       listAllData: data.map((item) => ({
      //         ...item,
      //         value: item.id,
      //         label: item.name || item.ten || item.address,
      //       })),
      //     });
      //     return;
      //   }
      // }
      fetchProvider.search({ page: 0, size: 999 }).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch[storeName].updateData({
            listAllData: res.data.map((item) => ({
              ...item,
              value: item.id,
              label: item.name || item.ten || item.address,
            })),
            totalElements: res.totalElements,
          });
          localStorage.setItem(
            `LIST_ALL__${storeName}`,
            JSON.stringify({
              time: moment(),
              data: res.data,
            })
          );
        }
      });
    },
    ...customEffect({ dispatch }),
  }),
});
