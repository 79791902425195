import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Bread = styled.div`
  display: flex;
  i {
    color: var(--blue-5);
    margin-top: 3px;
    margin-right: 10px;
    font-size: 20px;
  }
`;

const mapBread = [
  {
    path: "/manage/program",
    object: {
      icon: "ni ni-book-bookmark text-blue",
      title: "Chương trình đào tạo",
    },
  },
  {
    path: "/manage/course",
    object: {
      icon: "ni ni-archive-2 text-primary",
      title: "Khóa học",
    },
  },
  {
    path: "/manage/info-course/:",
    object: {
      icon: "ni ni-archive-2 text-primary",
      title: "Chi tiết khóa học",
    },
  },
  {
    path: "/manage/make-schedule",
    object: {
      icon: "ni ni-calendar-grid-58 text-primary",
      title: "Xếp lịch",
    },
  },
  {
    path: "/manage/timetable",
    object: {
      icon: "far fa-clock text-primary",
      title: "Thời khóa biểu",
    },
  },
  {
    path: "/manage/notifications",
    object: {
      icon: "far fa-bell text-primary",
      title: "Thông báo",
    },
  },
  {
    path: "/manage/account",
    object: {
      icon: "fas fa-users text-primary",
      title: "Quản lý tài khoản",
    },
  },
  {
    path: "/manage/category",
    object: {
      icon: "ni ni-square-pin text-primary",
      title: "Địa điểm đào tạo",
    },
  },
  {
    path: "/manage/training-group",
    object: {
      icon: "fas fa-object-group text-primary",
      title: "Nhóm đào tạo",
    },
  },
  {
    path: "/manage/account-group",
    object: {
      icon: "fas fa-user-shield text-primary",
      title: "Nhóm tài khoản",
    },
  },
  {
    path: "/manage/administrative",
    object: {
      icon: "fas fa-info text-primary",
      title: "Thông tin hành chính",
    },
  },
  {
    path: "/manage/user-profile",
    object: {
      icon: "fas fa-user-circle text-primary",
      title: "Thông tin cá nhân",
    },
  },
  {
    path: "/manage/ho-so",
    object: {
      icon: "fas fa-house-user text-primary",
      title: "Quản lý Hồ sơ",
    },
  },
  // {
  //   path: "/manage/class",
  //   object: {
  //     icon: "fas fa-clipboard-list text-primary",
  //     title: "Danh sách các lớp",
  //   },
  // },
  {
    path: "/manage/register-course",
    object: {
      icon: "ni ni-cart text-primary",
      title: "Đăng ký khóa học",
    },
  },
  {
    path: "/manage/ket-qua-hoc-tap",
    object: {
      icon: "ni ni-single-copy-04 text-primary",
      title: "Kết quả học tập",
    },
  },
  {
    path: "/manage/has-register",
    object: {
      icon: "ni ni-cart text-primary",
      title: "Danh sách đăng ký",
    },
  },
  {
    path: "/manage/don-vi-ho-tro",
    object: {
      icon: "ni ni-building text-primary",
      title: "Đơn vị hỗ trợ",
    },
  },
];

const Breadcrumb = ({ style }) => {
  const [state, setState] = useState({});
  const param = useParams();

  const checkUrl = (url) => {
    const arrPath = window.location.pathname.split("/");
    const arrUrl = url.split("/");
    if (arrPath.length !== arrUrl.length) return false;

    return arrPath.reduce(
      (acc, cur, index) =>
        acc && (cur === arrUrl[index] || arrUrl[index] === ":"),
      true
    );
  };

  useEffect(() => {
    const url = window.location.pathname;
    let item = mapBread.find((item) => checkUrl(item.path))?.object;
    setState({ url, item });
  }, [param]);

  return (
    <Bread style={style}>
      {state.item?.iconSvg ? (
        state.item.iconSvg
      ) : (
        <i className={state.item ? state.item.icon : ""} />
      )}
      <h2>
        <b>{state.item && state.item.title}</b>
      </h2>
    </Bread>
  );
};

export default Breadcrumb;
