import { Content } from "@containers/Content";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

const Authorization = ({ path, roles = [], authorities, key, ...rest }) => {
  if (roles?.length && !authorities?.some((a) => roles.some((r) => a === r))) {
    // window.location.href = "/login";
    return (
      <Content>
        <div
          className="content"
          style={{ fontWeight: 900, fontSize: 20, textAlign: "center" }}
        >
          Bạn không đủ quyền để truy cập tính năng này
        </div>
      </Content>
    );
  }

  return (
    <Switch key={key}>
      {typeof path === "string" ? (
        <Route path={path} {...rest} />
      ) : (
        path.map((item, index) => <Route path={item} {...rest} key={index} />)
      )}
    </Switch>
  );
};

export default connect((state) => ({
  authorities: state?.auth?.currentUser?.authorities,
}))(Authorization);
