import authProvider from "@data-access/auth";
import cacheProvider from "@data-access/data-cache";
import clientUtils from "@src/utils/client-utils";
import { toast } from "react-toastify";

const initState = () => {
  const currentUser = cacheProvider.read("", "AUTH");
  if (
    !currentUser &&
    !["/auth/login", "/auth/register"].some(
      (item) => item === window.location.pathname
    )
  ) {
    window.location.href = "/auth/login";
  }
  if (currentUser)
    clientUtils.auth = "Bearer " + currentUser.access_token || "";
  return {
    currentUser,
  };
};

export default {
  state: initState(),
  reducers: {
    updateData(state, payload = {}) {
      return { ...state.currentUser, ...payload };
    },
    updateAvatar(state, payload={}){
      return {
        ...state,
        currentUser:{
          ...state.currentUser, 
          ...payload,
        }
      }
    }
  },
  effects: (dispatch) => ({
    onLogin: ({ username, password }, state) => {
      return new Promise((resolve, reject) => {
        if (!username || !password) {
          toast.error("Tên đăng nhập hoặc mật khẩu chưa nhập");
          return;
        }
        authProvider.login(username, password).then((res) => {
          if (res && res.code === 0) {
            toast.success("Đăng nhập Thành Công");
            const _PERMISSION = res.data.authorities.reduce(
              (acc, current) => ({ ...acc, [current]: true }),
              {}
            );
              // console.log('')
            cacheProvider.save("", "AUTH", { ...res.data, _PERMISSION });

            setTimeout(() => {
              window.location.href = "/manage/user-profile";
              resolve(res);
            }, 500);
          } else if (res.code === 405) {
            toast.error("Tài khoản đã bị khóa");
            reject();
          } else {
            toast.error(res.message);
            reject();
          }
        });
      });
    },
  }),
};
