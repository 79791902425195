import nhomTKProvider from "@data-access/nhom-tai-khoan";

export default {
  state: {
    listGroupAccount: [],
    listAll: [],
    currentAccountRole:{},
    totalElements: 0,
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    getData: (payload, state) => {
      nhomTKProvider.search(payload).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.group_account.updateData({
            listGroupAccount: res.data,
            totalElements: res.totalElements,
          });
        }
      });
    },
    getAll: (payload, state) => {
      const nhomDaoTao = state.auth.currentUser?._PERMISSION?.ROLE_ADMIN
        ? payload.nhomDaoTao
        : state.auth.currentUser?.nhomDaoTao;
      nhomTKProvider
        .search({ ...payload, nhomDaoTao, page: 0, size: 100 })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.group_account.updateData({
              listAll: res.data,
              totalElements: res.totalElements,
            });
          }
        });
    },
    getCurrentAccountRole: (payload, state) => {
      nhomTKProvider.searchCurrentRole(payload).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.group_account.updateData({
            currentAccountRole: res.data,
          });
        }
      });
    },
  }),
};
