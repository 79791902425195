import nhomDTProvider from "@data-access/nhom-dao-tao";

export default {
  state: {
    listData: [],
    totalElements: 0,
    listAllNhomDaoTao: [],
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    getData: (payload, state) => {
      nhomDTProvider.search(payload).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.nhomDaoTao.updateData({
            listData: res.data,
            totalElements: res.totalElements,
          });
        }
      });
    },
    getAll: (payload, state) => {
      nhomDTProvider
        .search({ ...payload, active: true, page: 0, size: 999999 })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.nhomDaoTao.updateData({
              listAllNhomDaoTao: res.data,
            });
          }
        });
    },
  }),
};
