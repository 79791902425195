const prefix = "/api/medical-education/v1";

module.exports = {
  api: {
    course: {
      SEARCH: prefix + "/course",
      UPDATE: prefix + "/course",
      DELETE: prefix + "/course",
    },
    program: {
      SEARCH: prefix + "/program",
      UPDATE: prefix + "/program",
      DELETE: prefix + "/program",
    },
    subject: {
      SEARCH: prefix + "/subject",
      UPDATE: prefix + "/subject",
      DELETE: prefix + "/subject",
    },
    class: {
      SEARCH: prefix + "/class",
      UPDATE: prefix + "/class/",
      DELETE: prefix + "/class",
    },
    place: {
      SEARCH: prefix + "/place",
      UPDATE: prefix + "/place/",
      DELETE: prefix + "/place",
    },
    user: {
      SEARCH: prefix + "/user",
      UPDATE: prefix + "/user/",
      DELETE: prefix + "/user/",
      APPROVE_TEACHER: prefix + "/user/admin-approve-teacher/",
      UPLOAD_AVATAR: prefix + "/user/upload-avatar",
      CHANGE_PASSWORD: prefix + "/user/change-password",
      LOGIN: prefix + "/user/login",
    },
    register: {
      REGISTER_COURSE: prefix + "/register",
    },
    healthFacility: {
      SEARCH: prefix + "/health-facility",
      UPDATE: prefix + "/health-facility/",
      DELETE: prefix + "/health-facility",
    },
    schedule: {
      SEARCH: prefix + "/schedule",
      UPDATE: prefix + "/schedule/",
      DELETE: prefix + "/schedule/",
      COUNT_CHANGE_SCHEDULE: prefix + "/schedule/count-change-schedule",
      FIND_ALL_CHANGE: prefix + "/schedule/find-all-change-schedule",
      CHANGE_SCHEDULE: prefix + "/schedule/change-schedule/",
    },
    result: {
      SEARCH: prefix + "/result",
      UPDATE: prefix + "/result/",
      DELETE: prefix + "/result/",
    },
    notification: {
      SEARCH: prefix + "/notification",
      UPDATE: prefix + "/notification/",
      DELETE: prefix + "/notification/",
      COUNT: prefix + "/notification/read/count",
    },
  },
  listHiddenPage: ["/admin/user-profile"],
};
