import { Content } from "@containers/Content";
import { Form as FormAntd, Modal } from "antd";
import styled from "styled-components";

export const Screen = styled(Content)``;

export const WrapperStyledLogin = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-login {
    width: 400px;
    border-radius: 15px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.75);
    padding-top: 20px;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
  }
  .group-input {
    padding: 10px 36px;
    .child {
      height: 52px;
      display: flex;
      background-color: white;
      width: 100%;
      border-radius: 6px;
      .icon {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .input {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .input:focus {
        border-color: none;
      }
      input {
        border: none;
        color: #756f86;
        font-weight: 500;
        font-size: 18px;
      }
      input:focus {
        border: 0;
        outline: 0;
        box-shadow: none;
      }
      input::placeholder {
        color: #756f86;
        font-size: 18px;
      }
    }
    .child:focus-within {
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }
  .btn-submit {
    background-color: #0880ae;
    /* height: 52px; */
    /* display: flex; */
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 6px;
  }
  .group-tab {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding: 10px;
    .btn-tab {
      color: #0880ae;
      font-weight: bold;
      height: 30px;
      width: 120px;
      font-size: 16px;
      text-align: center;
      padding: 5px;
      border-radius: 4px;
      display: flex;
      cursor: pointer;

      img {
        /* margin-top: 3px; */
        width: 18px;
        height: 18px;
      }
    }
    .btn-tab_active {
      color: white;
      background-color: #0880ae;
    }
  }
  .group-checkbox {
    justify-content: space-between !important;
    margin-bottom: 10px;
    .ant-checkbox-wrapper {
      color: #0880ae;
    }
  }
  .footer-form {
    background-color: white;
    line-height: 50px;
    padding: 0 15px;
    margin-bottom: 30px;
    .register {
      color: #0880ae;
      cursor: pointer;
      margin-left: 7px;
    }
  }
  .mb-0 {
    margin-top: 0 !important;
  }
`;
