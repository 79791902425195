import API from "@variables/api";
import clientUtils from "../utils/client-utils";
import baseProvider from "./base-provider";

export default {
  ...baseProvider({ url: API.thongBao }),
  read(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", API.thongBao + "/read/" + id, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
