import userProvider from "@data-access/user";
import { isEmail } from "@src/utils/common";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Input, Row } from "reactstrap";
import { WrapperStyledLogin } from "../styled";

const Register = ({ history }) => {
  const [state, _setState] = useState({});
  const setState = (data) => {
    _setState((pre) => ({ ...pre, ...data }));
  };

  const create = () => {
    const { email, fullName, username, password, password1 } = state;
    const body = { email, fullName, username, password };
    if (!isEmail(email)) {
      toast.error("Email không đúng định dạng");
      return;
    }
    if (!email || !fullName || !username || !password || !password1) {
      toast.error("Vui lòng điền đầy đủ thông tin");
      return;
    }
    if (password != password1) {
      toast.error("Mật khẩu không đúng");
      return;
    }
    userProvider.create(body).then((json) => {
      if (json && json.code === 0 && json.data) {
        toast.success("Đăng ký thành công. Vui lòng đăng nhập tài khoản!");
        setTimeout(() => {
          history.push("/login");
        }, 1000);
      } else if (json && json.code === 401) {
        window.location.href = "/login";
      } else {
        setState({ ...state, loading: false });
        toast.error(json.message);
      }
    });
  };

  return (
    <WrapperStyledLogin>
      <div className="form-login">
        <Row className="group-tab">
          <div className="btn-tab" onClick={() => history.push("/login")}>
            <img src={require("@assets/images/login.png").default} />
            <span>Đăng nhập</span>
          </div>
          <div className="btn-tab btn-tab_active">
            <img src={require("@assets/images/register-active.png").default} />
            <span>Đăng ký</span>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/mail.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Email *"
                value={state.email}
                type="text"
                autoComplete="off"
                onChange={(e) => setState({ email: e.target.value })}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/name.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Họ và tên *"
                value={state.fullName}
                type="text"
                autoComplete="off"
                onChange={(e) => setState({ fullName: e.target.value })}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/account.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Tài khoản *"
                value={state.username}
                type="text"
                autoComplete="nope"
                onChange={(e) => setState({ username: e.target.value })}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/key.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Mật khẩu"
                value={state.password}
                type="text"
                autoComplete="nope"
                type="password"
                onChange={(e) => setState({ password: e.target.value })}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="child">
            <div className="icon">
              <img
                alt="..."
                src={require("@assets/images/key.png").default}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <div className="input">
              <Input
                placeholder="Nhập lại mật khẩu"
                value={state.password1}
                type="text"
                autoComplete="off"
                type="password"
                onChange={(e) => setState({ password1: e.target.value })}
              />
            </div>
          </div>
        </Row>
        <Row className="group-input">
          <div className="btn-submit" onClick={create}>
            Đăng ký
          </div>
        </Row>
      </div>
    </WrapperStyledLogin>
  );
};

export default Register;
