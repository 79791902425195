import clientUtils from "../utils/client-utils";
import constants from "../utils/const";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getBody({
    id,
    fullName,
    age,
    gender,
    address,
    phoneNumber,
    email,
    username,
    password,
    avatar,
    birth,
    cmnd,
    role,
    status,
  }) {
    return {
      id,
      fullName,
      age,
      gender,
      address,
      phoneNumber,
      email,
      username,
      password,
      avatar,
      birth,
      cmnd,
      role,
      status,
    };
  },
  search({ page, size, ...param }) {
    const url = Object.keys(param).reduce(
      (acc, key) => (param[key] ? `${acc}&${key}=${param[key]}` : acc),
      `${constants.api.user}?page=${page || 0}&size=${size || 10}&sort=updatedAt,desc`
    );

    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", url, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.user + "/register", {
          ...data,
          active: true,
        })
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  update(id, data) {
    // const body = this.getBody(data);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user + "/" + id, data)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  lockOrUnlock(id, active) {
    // const body = this.getBody(data);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("patch", constants.api.user + "/" + id, {
          active: !!active,
        })
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("delete", constants.api.user + "/" + id, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  resetPassword(data) {
    const body = this.getBody(data);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user + "/reset-password", body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  approveTeacher(id, data) {
    let body = { subjectsId: data };
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.approveTeacher + id, body)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  profile() {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user + "/profile", {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  importuser(form) {
    return new Promise((resolve, reject) => {
      clientUtils
        .upload("post", constants.api.user + "/import", form)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  importuser(form) {
    return new Promise((resolve, reject) => {
      clientUtils
        .upload("post", constants.api.user + "/import", form)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  administrative: {
    getHocHams: ({ page, size, ...search }) => {
      let searchString = "";
      Object.keys(search).forEach((key) => {
        if (search[key]) searchString = `${searchString}&${key}=${search[key]}`;
      });
      return new Promise((resolve, reject) => {
        clientUtils
          .upload(
            "get",
            `${constants.api.administrative.dmHocHam}?page=${page}&size=${size}${searchString}`
          )
          .then((x) => {
            resolve(x);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getHocVis: ({ page, size, ...search }) => {
      let searchString = "";
      Object.keys(search).forEach((key) => {
        if (search[key]) searchString = `${searchString}&${key}=${search[key]}`;
      });
      return new Promise((resolve, reject) => {
        clientUtils
          .upload(
            "get",
            `${constants.api.administrative.dmHocVi}?page=${page}&size=${size}${searchString}`
          )
          .then((x) => {
            resolve(x);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getChucVus: ({ page, size, ...search }) => {
      let searchString = "";
      Object.keys(search).forEach((key) => {
        if (search[key]) searchString = `${searchString}&${key}=${search[key]}`;
      });
      return new Promise((resolve, reject) => {
        clientUtils
          .upload(
            "get",
            `${constants.api.administrative.dmChucVu}?page=${page}&size=${size}${searchString}`
          )
          .then((x) => {
            resolve(x);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    position: {
      create: (data = {}) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi("post", constants.api.administrative.dmChucVu, data)
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      update: (data = {}) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi(
              "put",
              `${constants.api.administrative.dmChucVu}/${data.id}`,
              data
            )
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      delete: (id) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi(
              "delete",
              `${constants.api.administrative.dmChucVu}/${id}`
            )
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
    },
    academicDegree: {
      create: (data = {}) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi("post", constants.api.administrative.dmHocVi, data)
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      update: (data = {}) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi(
              "put",
              `${constants.api.administrative.dmHocVi}/${data.id}`,
              data
            )
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      delete: (id) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi(
              "delete",
              `${constants.api.administrative.dmHocVi}/${id}`
            )
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
    },
    academicRank: {
      create: (data = {}) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi("post", constants.api.administrative.dmHocHam, data)
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      update: (data = {}) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi(
              "put",
              `${constants.api.administrative.dmHocHam}/${data.id}`,
              data
            )
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      delete: (id) => {
        return new Promise((resolve, reject) => {
          clientUtils
            .requestApi(
              "delete",
              `${constants.api.administrative.dmHocHam}/${id}`
            )
            .then((x) => {
              resolve(x);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
    },
  },
  getRoles: ({ page, size, ...search }) => {
    let searchString = "";
    Object.keys(search).forEach((key) => {
      if (search[key]) searchString = `${searchString}&${key}=${search[key]}`;
    });
    return new Promise((resolve, reject) => {
      clientUtils
        .upload(
          "get",
          `${constants.api.roles}?page=${page}&size=${size}${searchString}`
        )
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  // downloadTemplate() {
  //   return new Promise((resolve, reject) => {
  //     clientUtils
  //       .upload("get", "uploads/temmplate.xlsx", {})
  //       .then((x) => {
  //         resolve(x);
  //       })
  //       .catch((e) => {
  //         reject(e);
  //       });
  //   });
  // },
};
