import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import Header from "@containers/Header";
import { Row, Col, Card, message } from "antd";
import styled from "styled-components";
import { menu } from "./constants";
import Icon from "@ant-design/icons";
import { useHistory } from "react-router";

const Body = styled.div`
  padding-top: 20px;
  margin-bottom: 90px;
  .head {
    padding: 15px;
  }

  .ant-col {
    text-align: center;
    padding: 20px;
  }
  .group-menu {
    padding-top: 5px;
    .ant-card {
      /* margin-top: 15px; */
      border-radius: 7px;
      padding-top: 5px;

      .head-card {
        display: flex;
        justify-content: center;
        margin-top: -30px;
        .title {
          background-color: #f3f4f6;
          padding: 2px 10px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          font-weight: bold;
          font-size: 18px;
          color: #fff;
          top: -1px;
        }
      }
      .content-card {
        .anticon {
          font-size: 40px;
        }
        .item {
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          &.active,
          &:hover {
            background-color: #e8f1f8;
          }
        }
      }
    }
  }

  @media (max-width: 576px) and (min-width: 370px) {
    .sm-2-col {
      max-width: 50%;
      flex-basis: 50%;
    }
  }
  @media (max-width: 991px) {
    .responsive-content__center {
      justify-content: center;
    }
  }
`;
const HomePage = ({ auth }) => {
  const history = useHistory();

  const listMenu = useMemo(() => {
    return menu;
  }, []);
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div
          className="main-panel"
          id="menu"
          style={{ scrollBehavior: "smooth" }}
        >
          <Body className="container-fluid">
            <Row>
              {listMenu.map((item, index) => {
                return (
                  <Col className="group-menu" sm={24} md={24} lg={item.span}>
                    <Card key={index} span={4}>
                      <div className="head-card">
                        <div
                          className="title"
                          style={{
                            backgroundColor: item.color
                              ? item.color
                              : "#327AB9",
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                      <div className="content-card">
                        <Row
                          className="responsive-content__center"
                          style={{
                            justifyContent: item.center ? "center" : "",
                          }}
                        >
                          {(item.childrens || []).map((child, idx) => {
                            const colSpanChild = [1, 2, 3, 4].some(
                              (element) =>
                                element ===
                                  item.childrens.filter(
                                    (element) => !element.span
                                  ).length && !item.center
                            )
                              ? 24 /
                                item.childrens.filter(
                                  (element) => !element.span
                                ).length
                              : 4;

                            return (
                              <Col
                                className="sm-2-col"
                                xs={24}
                                sm={8}
                                md={6}
                                lg={
                                  child.span
                                    ? 24 /
                                      item.childrens.filter(
                                        (element) => !element.span
                                      ).length
                                    : colSpanChild
                                }
                                id={index + "_" + idx}
                              >
                                <div
                                  className={
                                    "item pointer" +
                                    (child.link &&
                                    child.link ===
                                      history.location.state?.prevPath
                                      ? " active"
                                      : "")
                                  }
                                  onClick={() => {
                                    if (child.link === undefined) {
                                      message.info("Tính năng đang phát triển");
                                    } else {
                                      history.push(child.link);
                                    }
                                  }}
                                >
                                  <Icon component={child.icon} />
                                  <div style={{ marginTop: "20px" }}>
                                    <b>{child.name?.toUpperCase()}</b>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            {/* <div className="d-flex justify-content-center">
            <div>
              <div className="powered-by full">
                Sản phẩm cung cấp bởi <img src={LogoISOFH} />
              </div>
              <div className="powered-by compact">
                <img src={CompactLogo} />
              </div>
            </div>
          </div> */}
          </Body>
        </div>
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    auth: state.auth.auth,
  };
})(HomePage);
