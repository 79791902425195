import fetchProvider from "@data-access/hoc-vi-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "hocVi",
    initStore: {},
  }),
};
