export const prefix = "/api/medical-education/v1";

export default {
  program: prefix + "/program",
  course: prefix + "/course",
  baiGiang: prefix + "/bai-giang",
  place: prefix + "/place",
  util: prefix + "/utils",
  role: prefix + "/role",
  ngayTruc: prefix + "/ngay-truc",
  donViHoTro: prefix + "/don-vi-ho-tro",
  dinhMucHoTro: prefix + "/dinh-muc-ho-tro",
  statistics: prefix + "/thong-ke",
  thongBao: prefix + "/thong-bao",
  coSoDaoTao: prefix + "/health-facility",
  diaDiem: prefix + "/place",
  chucVu: prefix + "/dm-chuc-vu",
  hocHam: prefix + "/dm-hoc-ham",
  hocVi: prefix + "/dm-hoc-vi",
  hoSo: prefix + "/ho-so",
};
