import { ReactComponent as create } from "@assets/svg/create.svg";
import { ReactComponent as topicNotAccept } from "@assets/svg/topicNotAccept.svg";
import { ReactComponent as topicAccept } from "@assets/svg/topicAccept.svg";
import { ReactComponent as managementTranningPlan } from "@assets/svg/managementTranningPlan.svg";
import { ReactComponent as notificationTranningPlan } from "@assets/svg/notificationTranningPlan.svg";
import { ReactComponent as dataCommunication } from "@assets/svg/dataCommunication.svg";
import { ReactComponent as listRotate } from "@assets/svg/listRotate.svg";
import { ReactComponent as manageRotate } from "@assets/svg/manageRotate.svg";
import { ReactComponent as manageNotification } from "@assets/svg/manageNotification.svg";
import { ReactComponent as reportContent } from "@assets/svg/reportContent.svg";
import { ReactComponent as manageUser } from "@assets/svg/manageUser.svg";
import { ReactComponent as category } from "@assets/svg/category.svg";


export const menu = [
  {
    title: "Quản lý đề cương",
    span: 10,
    childrens: [
      {
        name: "Tạo mới",
        icon: create,
      },
      {
        name: "Đề cương chưa duyệt",
        icon: topicNotAccept,
      },
      {
        name: "Đề cương đã duyệt",
        icon: topicAccept,
      },
    ],
  },
  {
    title: "Quản lý kế hoạch đào tạo tuyến dưới",
    span: 14,
    childrens: [
      {
        name: "Tạo mới",
        icon: create,
      },
      {
        name: "Quản lý kế hoạch đào tạo",
        icon: managementTranningPlan,
      },
      {
        name: "Thông báo kế hoạch đào tạo",
        icon: notificationTranningPlan,
      },
      {
        name: "Liên thông từ dữ liệu kế hoạch đào tạo",
        icon: dataCommunication,
      },
    ],
  },
  {
    title: "Quản lý kế hoạch nghiên cứu khoa học",
    span: 16,
    childrens: [
      {
        name: "Tạo mới",
        icon: create,
      },
      {
        name: "Kế hoạch nghiên cứu khoa học",
        icon: managementTranningPlan,
      },
      {
        name: "Thông báo kế hoạch NCKH",
        icon: notificationTranningPlan,
      },
      {
        name: "Liên thông dữ liệu từ NCKH",
        icon: dataCommunication,
      },
    ],
  },
  {
    title: "Kế hoạch luân chuyển cán bộ",
    span: 8,
    childrens: [
      {
        name: "Danh sách luân chuyển cán bộ",
        icon: listRotate,
      },
      {
        name: "Kế hoạch luân chuyển cản bộ",
        icon: manageRotate,
      },
    ],
  },
  {
    title: "Thông báo",
    span: 8,
    childrens: [
      {
        name: "Tạo mới",
        icon: create,
      },
      {
        name: "Quản lý thông báo",
        icon: manageNotification,
      },
    ],
  },
  {
    title: "Báo cáo hiệu quả đào tạo chuyển giao kỹ thuật",
    span: 16,
    color: "#EF5DA8",
    childrens: [
      {
        name: "Báo cáo nội dung chỉ đạo tuyến",
        icon: reportContent,
      },
      {
        name: "Báo cáo kết quả hoạt động đào tạo",
        icon: managementTranningPlan,
      },
      {
        name: "Báo cáo kết quả nghiên cứu khoa học",
        icon: notificationTranningPlan,
      },
      {
        name: "Báo cáo KQ luân chuyển cán bộ CGKT",
        icon: dataCommunication,
      },
    ],
  },
  {
    title: "Thiết lập chung",
    span: 24,
    childrens: [
      {
        name: "Quản trị người dùng",
        icon: manageUser,
      },
      {
        name: "Thiết lập phân quyền",
        icon: manageUser,
      },
      {
        name: "Danh mục nội dung đào tạo",
        icon: category,
      },
      {
        name: "Danh mục phân tuyến bệnh viện",
        icon: category,
      },
      {
        name: "Danh mục dịch vụ kỹ thuật",
        icon: category,
      },
      {
        name: "Danh mục chuyên ngành đào tạo",
        icon: category,
      },
      {
        name: "Danh mục phân loại thông báo",
        icon: category,
      },
      {
        name: "Danh mục phân loại trạng thái đề cương",
        icon: category,
      },
      {
        name: "Danh mục chức vụ",
        icon: category,
      },
      {
        name: "Danh mục hội đồng đào tạo",
        icon: category,
      },
      {
        name: "Danh mục cán bộ đào tạo chuyển giao",
        icon: category,
      },
    ],
  },
];
