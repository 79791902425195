import baseStore from "../base-store";
import fetchProvider from "@data-access/system-notification";
import { getCurrentUserId } from "@src/utils/common";

export default {
  ...baseStore({
    fetchProvider: fetchProvider,
    storeName: "notification",
    initStore: {
      listThongBaoChuaDoc: [],
      listAllThongBao: [],
      trangThai: 2, //1 là chưa đọc, 2 là đã đọc
    },
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = {
          ...state.notification.params,
          ...payload,
          nguoiNhanId: getCurrentUserId(),
        };
        dispatch.notification.updateData({
          params: newParams,
        });
        return new Promise((resolve, reject) => {
          fetchProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                let data = res.data;
                const listChuaDoc = data.filter((TB) => {
                  return TB?.trangThai === 1;
                });
                dispatch.notification.updateData({
                  listData: data,
                  listAllThongBao: data,
                  listThongBaoChuaDoc: listChuaDoc,
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },

      countNoti: (payload = {}, state) => {
        return new Promise((resolve, reject) => {
          fetchProvider
            .search({
              page: 0,
              size: 1,
              nhomDaoTao: state.global.nhomHienTai,
              nguoiNhanId: state.auth.currentUser?.id,
              trangThai: 1,
            })
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.notification.updateData({
                  numberNoti: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },

      read: (id, { notification: { listData, numberNoti } }) => {
        return new Promise((resolve, reject) => {
          fetchProvider
            .read(id)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                const newList = [...listData];
                const idx = listData.findIndex((i) => i.id === id);
                newList[idx].trangThai = 2;
                dispatch.notification.updateData({
                  listData: newList,
                  numberNoti: numberNoti - 1,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
    }),
  }),
};
