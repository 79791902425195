import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";

const Style = styled.div`
  padding: 30px 10px;
  background-color: #f7fafc;
`;

const Footer = () => {
  return (
    <Style>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted ml-3">
            © {new Date().getFullYear()}
            <a
              className="font-weight-bold ml-1"
              href="#"
              rel="noopener noreferrer"
              target="_blank"
            >
              ISOFH
            </a>
          </div>
        </Col>
      </Row>
    </Style>
  );
};

export default Footer;
