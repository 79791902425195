import styled from "styled-components";
import { Layout, Menu } from "antd";

const { Header } = Layout;
const { Item } = Menu;

export const Head = styled.div`
  height: 72px;
  padding: 5px 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 15px #aaa;
  font-family: "Nunito Sans";
  .box-user-info {
    display: flex;

    .name {
      margin-right: 25px;
      .fullname {
        font-weight: bold;
        font-size: 16px;
        color: #172b4d;
      }
      .username {
        color: #999;
      }
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  .header-content-right {
    display: flex;
    align-items: center;
    .header-notifications {
      margin-right: 24px;
      .header-notifi-icon svg {
        font-size: 20px;
      }
      .header-notifi-icon svg:hover {
        cursor: pointer;
      }
    }
  }
  .dropdown-box {
    display: flex;
    .dropdown-title {
      font-weight: 600;
      color: rgba(94, 114, 228, 1);
      padding-top: 3px;
      margin-right: 20px;
    }
    .dropdown-list {
      margin-right: 20px;
      margin-left: -10px;
      .ant-select {
        width: 170px;
      }
    }
    .dropdown-img {
      padding-top: 1.5px;
      img {
        width: 80%;
      }
    }
    .ant-select-arrow {
      color: rgba(94, 114, 228, 1);
    }
  }
`;

export const ItemMenu = styled(Item)`
  padding: 0.5rem 1rem;
  font-size: 16px;
  i {
    margin-right: 1rem;
    font-size: 1rem;
    vertical-align: -17%;
  }
`;
