import styled from "styled-components";
import { Layout } from "antd";
export const Style = styled(Layout.Content)`
  padding: 20px 0px;
  .body {
    background-color: white;
    padding: 10px 10px 20px 10px;
    margin: 10px;
    .head-content {
      display: flex;
      justify-content: space-between;
      padding: 10px 5px;
    }

    .div-search {
      display: flex;
      padding-bottom: 15px;
      .search-box {
        border-radius: 0;
      }
    }
  }
`;

export const Content = ({ style, ...props }) => (
  <Style style={style}>
    <div className="body">{props.children}</div>
  </Style>
);
