import fetchProvider from "@data-access/chuc-vu-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "chucVu",
    initStore: {},
  }),
};
