import { Collapse } from "antd";
import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { listRoute } from "./constant";
import ItemMenu from "./ItemMenu";
import { SidebarContent } from "./styled";
import { isEmpty } from "lodash";
// const logo = {
//   innerLink: "/admin",
//   imgSrc: require("@assets/img/brand/logo-isoft.png").default,
//   imgAlt: "...",
// };

const SideBar = ({
  loaiTaiKhoan,
  nhomDaoTao,
  logo,
  authorities = [],
  nhomHienTai,
  listnhomDaoTao,
  currentUser,
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [route, setRoute] = useState([]);
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const canVisible = (route) =>
    ((authorities.some((au) => route.role?.includes(au)) &&
      authorities.every((au) => !route.ignoreRole?.includes(au))) ||
      route.permissAll ||
      (route.loaiTaiKhoan?.includes(loaiTaiKhoan) &&
        !isEmpty(route.loaiTaiKhoan))) &&
    (isEmpty(route.visible) ||
      (!isEmpty(route.visible) && route.visible.includes(nhomHienTai)));

  const getRoutes = () => {
    const newRoutes = [];

    listRoute.forEach((route) => {
      if (route?.childrens?.length > 0) {
        const routeCustom = {
          ...route,
          childrens: route.childrens.reduce(
            (acc, current) => (canVisible(current) ? [...acc, current] : acc),
            []
          ),
        };
        if (routeCustom.childrens?.length > 0) newRoutes.push(routeCustom);
      } else if (canVisible(route)) {
        newRoutes.push(route);
      }
    });

    setRoute(newRoutes);
  };

  useEffect(() => {
    getRoutes();
  }, [listnhomDaoTao, nhomHienTai]);

  return (
    <SidebarContent
      className={
        "navbar-sidebar navbar-vertical fixed-left navbar-light bg-white navbar navbar-expand-md" +
        (collapseOpen ? " navbar-hidden" : "")
      }
      expand="md"
    >
      <div className="sidenar-header d-flex align-items-center">
        {logo ? (
          <div className="pt-0 mr-4">
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </div>
        ) : null}
        <div className="ml-auto" onClick={toggleCollapse}>
          {collapseOpen ? (
            <i className="ni ni-align-left-2 i-collapse" />
          ) : (
            <i className="ni ni-align-center i-collapse" />
          )}
        </div>
      </div>
      <div
        className="scrollbar-container ps ps--active-y pt-4"
        style={{ height: "auto", minHeight: "auto" }}
      >
        <div className="scrollbar-inner">
          <Collapse style={{ backgroundColor: "white", border: "none" }}>
            <ul className="navbar-nav">
              {(route || []).map((prop, key) => {
                return <ItemMenu key={key} item={prop} hidden={collapseOpen} />;
              })}
            </ul>
          </Collapse>
        </div>
      </div>
    </SidebarContent>
  );
};

export default connect(
  ({
    auth: { currentUser },
    global: { nhomHienTai },
    utils: { listnhomDaoTao },
  }) => ({
    currentUser,
    loaiTaiKhoan: currentUser?.loaiTaiKhoan,
    authorities: currentUser?.authorities,
    nhomHienTai,
    listnhomDaoTao,
  }),
  ({ nhomDaoTao: { getAll } }) => ({ getAllNhomDaoTao: getAll })
)(SideBar);
