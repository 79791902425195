import fetchProvider from "@data-access/facility";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "health_facility",
    initStore: { listDataSelect: [] },
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = { ...state.health_facility.params, ...payload };
        dispatch.health_facility.updateData({
          params: newParams,
        });

        return new Promise((resolve, reject) => {
          fetchProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.health_facility.updateData({
                  listData: res.data,
                  listDataSelect: res.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
      getData: (payload, state) => {
        fetchProvider.search(payload).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.health_facility.updateData({
              listData: res.data,
              totalElements: res.totalElements,
              listAllFacility: res.data,
            });
          }
        });
      },
      getAllFacility: (payload, state) => {
        fetchProvider
          .search({ ...payload, page: 0, size: 999999 })
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch.health_facility.updateData({
                listAllFacility: res.data,
              });
            }
          });
      },
    }),
  }),
};
