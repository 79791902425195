export const linkCDT = "http://10.0.0.87:2121/";
export const apiDemo = "https://api-qldt-demo.isofh.com";

export const UrlServer = () => {
  const domain = global.origin;
  const localhost = false;

  switch (domain) {
    // ip
    case "http://10.0.0.87:2122": // test
      return "http://10.0.0.45:8082";
    case "http://10.0.0.45:21022": // test dkth
      return "http://10.0.0.45:8011";
    case "http://10.0.0.45:21922": // test dhy
      return "http://10.0.0.45:8100";
    case "http://10.0.0.49:26122": // demo
      return "http://10.0.0.49:8084";
    case "http://172.17.50.175:2392": //production dhy
      return "http://172.17.50.175:8082";
    case "http://192.168.55.175:2392": //production dhy_ viện vào link này
      return "http://192.168.55.175:8082";
    case "http://192.168.0.242:2392": //production bve
      return "http://192.168.0.242:8084";
    case "http://192.168.18.158:2392": // production dkth
      return "http://192.168.18.158:8012";
    case "http://172.17.50.175:2395": // production dkth
      return "http://172.17.50.175:8085";

    // domain
    case "https://qldt-demo.isofh.com": // demo
      return "https://api-qldt-demo.isofh.com";
    case "https://qldt.benhviene.com": // production bve
      return "https://qldt-api.benhviene.com";
    case "https://qldt.bvxanhpon.vn": // production xanhpon
      return "https://api-qldt.bvxanhpon.vn";
    case "http://qldt.bvxanhpon.vn:26122": // production xanhpon
      return "http://api-qldt.bvxanhpon.vn:8084";
    case "https://qldt.benhviendaihocyhanoi.com:8443": //domain dhy production
      return "https://qldt-api.benhviendaihocyhanoi.com:8443";
  }
  return localhost ? "http://localhost:8082" : "http://10.0.0.45:8082";
};

export default {
  auth: "",
  serverApi: UrlServer(),
  requestApi(methodType, url, body, ignoreAuth) {
    return new Promise((resolve, reject) => {
      if (!body) body = {};
      if (methodType.toLowerCase() !== "get") {
        body = JSON.stringify(body);
      }
      this.requestFetch(
        methodType,
        url && url.indexOf("http") === 0 ? url : url,
        ignoreAuth
          ? {
              Accept: "application/json",
              "Content-Type": "application/json",
            }
          : {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.auth,
            },
        body
      )
        .then((s) => {
          s.json()
            .then((val) => {
              if (val.code === 401) {
                localStorage.clear();
                window.location.href = "/auth/login";
              }
              resolve(val);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          if (e && e.status === 401) {
            localStorage.clear();
            window.location.href = "/auth/login";
          }
          reject(e);
        });
    });
  },
  requestFetch(methodType, url, headers, body) {
    return new Promise((resolve, reject) => {
      let fetchParam = {
        method: methodType,
        headers,
      };

      if (methodType.toLowerCase() !== "get") {
        fetchParam.body = body;
      }
      return fetch(this.serverApi + url, fetchParam)
        .then((json) => {
          if (!json.ok) {
            reject(json);
          } else resolve(json);
        })
        .catch((e) => {
          // window.location.href = "/maintain";
          reject(e);
        });
    });
  },
  upload(methodType, url, form, then, catchFunc) {
    return new Promise((resolve, reject) => {
      return this.requestFetch(
        methodType,
        url && url.indexOf("http") === 0 ? url : url,
        {
          Authorization: this.auth,
        },
        form
      )
        .then((s) => {
          if (then) then(s);
          s.json()
            .then((val) => {
              if (val?.code === 401) {
                localStorage.clear();
                window.location.href = "/auth/login";
              }
              resolve(val);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          if (catchFunc) catchFunc(e);
          if (e && e.status === 401) {
            localStorage.clear();
            window.location.href = "/auth/login";
          }
          reject(e);
        });
    });
  },
};
