import changePassword from "@data-access/change-password";
import { Modal, Form, Input } from "antd";
import Button from "@components/Button";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { toast } from "react-toastify";

const ChangePass = (props, ref) => {
  const [state, setState] = useState({
    password: "",
    newPassword: "",
    checkPassword: "",
  });
  const [form] = Form.useForm();
  const [isRedirect, setIsRedirect] = useState(false);
  const handleSubmit = (values) => {
    if (state.newPassword != state.checkPassword) {
      toast.error("Check lại mật khẩu");
      return;
    } else {
      const body = {
        password: values.password,
        passwordChange: values.passwordChange,
      };
      changePassword.changePass(body).then((json) => {
        if (json.code === 0) {
          toast.success("Đổi mật khẩu thành công");
          form.resetFields();
          setState({ ...state, visible: false });
        } else if (json.code === 401) {
          // window.location.href = "/login";
        } else {
          toast.error(json.message);
        }
      });
    }
  };
  const handleChange = (field) => (e) => {
    setState({
      ...state,
      [field]: e.target.value,
    });
  };

  useImperativeHandle(ref, () => ({
    show: () => {
      setState({ ...state, visible: true });
    },
  }));

  const onCancel = () => {
    form.resetFields();
    setState((prevState) => ({ ...prevState, visible: false }));
  };

  return (
    <Modal
      visible={state.visible}
      title={
        <div style={{ width: "100%", textAlign: "center", fontWeight: 600 }}>
          Đổi mật khẩu
        </div>
      }
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={onCancel}
            className="btn-white"
            content="Trở lại"
            icon={"fa fa-times"}
          />
          {!state.isDetail && (
            <Button
              onClick={() => form.submit()}
              content="Lưu lại"
              icon={"fa fa-check"}
            />
          )}
        </div>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Mật khẩu cũ"
          name="password"
          required
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mật khẩu cũ",
            },
          ]}
        >
          <Input
            placeholder="Mật khẩu cũ"
            type="password"
            name="password"
            onChange={(e) => handleChange(e)}
            // autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          label="Mật khẩu mới"
          required
          name="passwordChange"
          rules={[
            {
              validator: (rules, value, callback) => {
                if (!value) {
                  callback("Vui lòng điền mật khẩu mới");
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            placeholder="Mật khẩu mới"
            type="password"
            onChange={handleChange("passwordChange")}
            // autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          label="Nhập lại mật khẩu mới"
          name="passwordChange2"
          required
          rules={[
            {
              validator: (rules, value, callback) => {
                if (!value) {
                  callback("Vui lòng điền mật khẩu mới");
                } else if (value !== state.passwordChange) {
                  callback("Mật khẩu không đúng");
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            placeholder="Nhập lại Mật khẩu mới"
            type="password"
            onChange={handleChange("passwordChange2")}
            // autoComplete="new-password"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(ChangePass);
