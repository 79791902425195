import { combineUrlParams } from "@src/utils/common";
import api from "@src/variables/api";
import clientUtils from "@utils/client-utils";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getUtils(payload) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", combineUrlParams(`${api.util}/enums`, payload), {})
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
};
