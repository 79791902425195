import { Badge } from "antd";
import { useSelector } from "react-redux";

export const listRoute = [
  {
    path: "/manage/program",
    name: "Chương trình đào tạo",
    icon: "ni ni-book-bookmark text-blue",
    role: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
    visible: [10],
    // visible:2,s
    // childrens: listAllNhomDaoTao.map((item) => ({
    //   path: "/manage/program?dmNhomDaoTaoId=" + item.id,
    //   name: item.ten,
    //   icon: "ni ni-book-bookmark text-blue",
    //   role: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
    // })),
    // [
    //   {
    //     path: "/manage/program-continue",
    //     name: "Liên tục",
    //     icon: "ni ni-book-bookmark text-blue",
    //     role: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
    //   },
    //   {
    //     path: "/manage/program-formal",
    //     name: "Chính quy",
    //     icon: "ni ni-book-bookmark text-blue",
    //     role: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
    //   },
    //   {
    //     path: "/manage/program-mentoring",
    //     name: "Chỉ đạo tuyến",
    //     icon: "ni ni-book-bookmark text-blue",
    //     role: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
    //   },
    // ],
  },
  {
    path: "/manage/course",
    name: "Danh sách khóa học",
    icon: "ni ni-ungroup text-blue",
    role: [
      "ROLE_ADMIN",
      "KH_DK_R",
      "KH_DK_CRUD",
      "KH_DH_R",
      "KH_DH_CRUD",
      "KH_HT_R",
      "KH_H_R",
      "KH_H_CRUD",
    ],
    visible: [10, 15, 20, 25],
  },
  {
    path: "/manage/register-course",
    name: "Đăng ký khóa học",
    icon: "ni ni-cart text-blue",
    role: ["DKKH_C"],
    visible: [10, 15, 20, 25],
  },

  // route cho teacher
  {
    path: "/manage/class",
    name: "Danh sách các lớp",
    icon: "fas fa-clipboard-list text-blue",
    role: ["ROLE_ADMIN", "ROLE_TEACHER"],
    visible: [9999], //không hiện phải để 1 số không phải là 10, 15, 20, 25
  },
  {
    path: "#",
    name: "Công việc",
    icon: "far fa-clock text-blue",
    visible: [10],
    childrens: [
      {
        path: "/manage/make-schedule",
        name: "Xếp lịch",
        icon: "ni ni-calendar-grid-58 text-primary",
        role: ["ROLE_ADMIN", "XL_CRUD"],
        visible: [10],
      },
      {
        path: "/manage/timetable",
        name: "Thời khóa biểu",
        icon: "far fa-clock text-blue",
        role: ["ROLE_ADMIN", "ROLE_TEACHER", "ROLE_STUDENT", "TKB_CRUD"],
        visible: [10],
      },
    ],
  },
  {
    path: "#",
    name: "Báo cáo thống kê",
    icon: "fas fa-chart-bar",
    visible: [10],
    loaiTaiKhoan: [10],
    childrens: [
      {
        path: "/manage/statistic/teaching",
        name: "Thống kê thông tin giảng dạy",
        icon: "fas fa-chalkboard-teacher",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [10],
      },
      {
        path: "/manage/statistic/course",
        name: "Thống kê thông tin khóa học",
        icon: "fas fa-book-medical",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [20],
      },
      {
        path: "/manage/statistic/student",
        name: "Thống kê thông tin học viên",
        icon: "fas fa-graduation-cap",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [10],
      },
      {
        path: "/manage/statistic/money",
        name: "Thống kê tiền hỗ trợ học viên",
        icon: "fas fa-credit-card",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [15],
      },
      {
        path: "/manage/statistic/schedule",
        name: "Thống kê lịch trực học viên",
        icon: "fas fa-briefcase",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [15],
      },
      {
        path: "/manage/statistic/student-money",
        name: "Thống kê chi trả học viên",
        icon: "fas fa-credit-card",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [15],
      },
      {
        path: "/manage/statistic/newCourse",
        name: "Thống kê các khóa học",
        icon: "fas fa-vials",
        role: ["ROLE_ADMIN", "TK_R"],
        visible: [10],
      },
    ],
  },
  // {
  //   path: "/manage/points",
  //   name: "Quản lý điểm",
  //   icon: "ni ni-book-bookmark text-blue",
  //   role: ["ROLE_ADMIN"],
  // },
  {
    path: "/manage/notifications",
    name: "Thông báo",
    icon: "fas fa-bell text-primary",
    role: ["ROLE_ADMIN", "TB_R"],
    visible: [10],
    ChildRight: () => {
      const { numberNoti = 0 } = useSelector((state) => state.notification);

      return <Badge count={numberNoti}></Badge>;
    },
  },
  {
    path: "/manage/ho-so",
    name: "Hồ sơ",
    icon: "fas fa-house-user text-primary",
    role: ["ROLE_ADMIN", "HS_R"],
    visible: [10, 15, 20],
  },
  {
    path: "/manage/account",
    name: "Quản lý tài khoản",
    icon: "fas fa-users text-primary",
    role: [
      "ROLE_ADMIN",
      "QLTK_R",
      "QLTK_CRUD",
      "QLTK_I",
      "QLTK_SV_CRUD",
      "QLTK_SV_R",
    ],
    visible: [10, 15, 20, 25],
  },
  {
    path: "#",
    name: "Thiết lập chung",
    icon: "fas fa-cogs text-primary",
    visible: [10, 15, 20, 25],
    childrens: [
      {
        path: "/manage/category",
        name: "Địa điểm đào tạo",
        icon: "ni ni-square-pin text-primary",
        role: ["ROLE_ADMIN", "DD_R", "DD_CRUD", "CSDT_R", "CSDT_CRUD"],
        visible: [10, 15, 20, 25],
      },
      // {
      //   path: "/manage/training-group",
      //   name: "Nhóm đào tạo",
      //   icon: "fas fa-object-group text-primary",
      //   role: ["ROLE_ADMIN", "NDT_R", "NDT_CRUD"],
      //   visible: [10,15,20,25],
      // },
      {
        path: "/manage/account-group",
        name: "Nhóm tài khoản",
        icon: "fas fa-user-shield text-primary",
        role: ["ROLE_ADMIN", "NTK_R", "NTK_U", "NTK_CD"],
        visible: [10, 15, 20, 25],
      },
      {
        path: "/manage/don-vi-ho-tro",
        name: "Đơn vị hỗ trợ",
        icon: "fas fa-user-shield text-primary",
        role: ["ROLE_ADMIN"],
        visible: [15],
      },
      {
        path: "/manage/administrative",
        name: "Thông tin hành chính",
        icon: "fas fa-info text-primary",
        role: [
          "ROLE_ADMIN",
          "TTHC_CV_R",
          "TTHC_HH_R",
          "TTHC_HV_R",
          "TTHC_CV_CRUD",
          "TTHC_HH_CRUD",
          "TTHC_HV_CRUD",
        ],
        visible: [10, 15, 20, 25],
      },
    ],
  },
  {
    path: "/manage/user-profile",
    name: "Thông tin cá nhân",
    icon: "fas fa-user-circle text-primary",
    permissAll: true,
    visible: [10, 15, 20, 25],
  },

  // các route cho học viên
  {
    path: "/manage/has-register",
    name: "Danh sách đăng ký",
    icon: "ni ni-cart text-blue",
    role: ["DKKH_R"],
    visible: [10, 15, 20, 25],
  },
  // {
  //   path: "/manage/result-student",
  //   name: "Kết quả học tập",
  //   icon: "ni ni-single-copy-04 text-blue",
  //   role: ["QLD_R"],
  //   visible: [10, 15, 20, 25],
  // },
  {
    path: "/manage/ket-qua-hoc-tap",
    name: "Kết quả học tập",
    icon: "ni ni-single-copy-04 text-blue",
    role: [],
    loaiTaiKhoan: [20],
    visible: [10, 15, 20, 25],
  },
];

export const currentPage = (nhomHienTai) => {
  const arrPath = listRoute.reduce(
    (acc, cur) => [...acc, ...(cur.childrens ? cur.childrens : [cur])],
    []
  );

  if (
    arrPath
      .filter((item) => item.visible?.includes(nhomHienTai))
      .some((item) => item.path == window.location.pathname)
  ) {
    return window.location.pathname;
  } else {
    if (nhomHienTai === 10) {
      return "/manage/program";
    } else if (nhomHienTai === 15) {
      return "/manage/course";
    } else if (nhomHienTai === 20) {
      return "/manage/course";
    } else if (nhomHienTai === 25) {
      return "/manage/course";
    }
  }
  return "/manage/course";
};
