import fetchProvider from "@data-access/dia-diem-provider";
import baseStore from "../base-store";
import moment from "moment";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "diaDiem",
    initStore: {},
    customEffect: ({ dispatch }) => ({
      getAllPlace: (payload = {}, state)=>{
        const healthFacilityId = payload?.healthFacilityId;
        fetchProvider.search({ page: 0, size: 999, healthFacilityId}).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.diaDiem.updateData({
              listAllData: res.data.map((item) => ({
                ...item,
                value: item.id,
                label: item.name || item.ten || item.address,
              })),
              totalElements: res.totalElements,
            });
            localStorage.setItem(
              `LIST_ALL__${"diaDiem"}`,
              JSON.stringify({
                time: moment(),
                data: res.data,
              })
            );
          }
        });
      }
    })
  }),
};
