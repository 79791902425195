import { Badge as Bdg } from "reactstrap";
import { Badge } from "antd";
import constants from "./const";
import EventEmitter from "events";
import moment from "moment";
import { getState } from "@redux/index";

export const defaultState = {
  loading: true,
  reload: false,
  isShowDetail: false,
  isShowModal: false,
  idDetail: null,
  indexDetail: null,
  dataDetail: null,
  isCreate: true,
  isDetail: false,

  dataRender: null,

  param: { page: 0, size: 10 },
  totalPage: 0,
  totalElements: 0,
  role: "",
};
export function splitParamsUrl(str) {
  let pair = null;
  const data = [];
  const queryParams = str.split("?")[1];
  const params = queryParams.split("&");

  params.forEach((param) => {
    pair = param.split("=");
    data.push({ [pair[0]]: pair[1] });
  });

  return data;
}
export function splitParams() {
  let pair = null;
  const data = {};
  const { search } = window.location;
  if (!search) return {};
  const queryParams = search.split("?")[1];
  const params = queryParams.split("&");

  params.forEach((param) => {
    pair = param.split("=");
    data[pair[0]] = pair[1];
  });

  return data;
}
export function getRole(role) {
  switch (role) {
    case constants.roles.admin.value:
      return constants.roles.admin.name;
    case constants.roles.teacher.value:
      return constants.roles.teacher.name;
    case constants.roles.student.value:
      return constants.roles.student.name;
  }
}
export function getDay(day) {
  return constants.day
    .filter((item) => item.value === day)
    .map((item) => {
      return item.label;
    });
}
export function getDayByDate(date) {
  return (
    constants.day.find((item) => item.enName === moment(date).format("dddd")) ||
    {}
  ).label;
}
export function getDayByEnName(enName) {
  return (constants.day.find((item) => item.enName === enName) || {}).label;
}
export function getKipHoc(kip) {
  return constants.kipOption
    .filter((item) => item.value === kip)
    .map((item) => {
      return item.label;
    });
}
export function getStatusRegister(register) {
  return constants.statusRegisterOption.find((item) => item.value === register);
}
export function getCourseStatus(status) {
  return constants.courseStatusOptions.find((item) => item.value === status);
}
export function getRegisterStatusComponent(status) {
  return constants.statusRegisterOption
    .filter((item) => item.value === status)
    .map((item) => {
      return (
        <Bdg className="badge-dot mr-4">
          <i className={item.color} />
          {item.name}
        </Bdg>
      );
    });
}
export function getRegisterStatus(status) {
  return constants.statusRegisterOption.find((item) => item.value === status);
}
export function combineUrlParams(url = "", params = {}) {
  const keys = Object.keys(params);
  const paramUrl = keys
    .reduce(
      (result, key) =>
        (
          Array.isArray(params[key])
            ? params[key].length > 0
            : params[key] ||
              params[key] === 0 ||
              typeof params[key] === "boolean"
        )
          ? [...result, `${key}=${params[key]}`]
          : [...result],
      []
    )
    .join("&");
  return `${url}?${paramUrl}`;
}
export function convertPrice(money) {
  return new Intl.NumberFormat("de-DE").format(money) + "";
}

export function getStatusUser(type) {
  if (type === 1)
    return {
      className: "w100",
      color: "var(--red)",
      name: "Khóa",
    };
  else
    return {
      className: "w100",
      color: "var(--green)",
      name: "Hoạt động",
    };
}

export function getGenderName(gender) {
  if (gender === constants.gender.nam.value) return constants.gender.nam.label;
  else return constants.gender.nu.label;
}

export function getCourseStatusBadge(status) {
  const item = constants.courseStatusOptions.find((item) => item.id === status);

  return (
    <Badge
      className="w100 text-white pointer "
      style={{ backgroundColor: item.color }}
      count={item.name}
    />
  );
}

Number.prototype.formatPrice = function () {
  return Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(this);
};

moment.prototype.dateCount = function () {
  return this < moment()
    ? moment().subtract(this.add(1, "days")).format("DD") + " Ngày trước"
    : this.subtract(moment().add(1, "days")).days() + " Ngày";
};
Date.prototype.dateFromNow = function () {
  const now = new Date();
  const calc = parseInt(
    (this.getTime() - now.getTime()) / (24 * 60 * 60 * 1000) + 1
  );
  return calc < 0 ? -calc + " Ngày trước" : calc + " Ngày";
};

export const nonAccentVietnamese = (str = "") => {
  if (!str) return "";
  str = str.toString().toLowerCase();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const nonAccentVietnameseKeepCase = (str = "") => {
  if (!str) return "";
  str = str.toString();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ữ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const eventEmitter = new EventEmitter();
eventEmitter.setMaxListeners(32);

export const getXepLoaiTheoDiem = (diem) => {
  if (!diem) return "";
  if (diem < 6.5) return "Trung bình";
  if (diem < 8) return "Khá";
  return "Giỏi";
};

export const getIdFromUrl = () => {
  const arr = window.location.pathname.split("/");
  return Number.isNaN(parseInt(arr[arr.length - 1])) ? -1 : arr[arr.length - 1];
};
Number.prototype.formatPrice = function () {
  return this.toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    .replace(".00", "")
    .replace(/,/g, ".");
};
String.prototype.formatPrice = function () {
  try {
    return parseInt(this)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
      .replace(".00", "")
      .replace(/,/g, ".");
  } catch (error) {}
  return this;
};

/**
 * objectPermission:
 * {
 * key1: ["ROLEA","ROLEB"],
 * key2: ["ROLEA","ROLEB"],
 * key3: ["ROLEA","ROLEB"],
 * }
 */
export const validatePermission = (objectPermission = {}) => {
  const store = require("../redux");
  const { authorities } = store.getState().auth?.currentUser;

  return Object.keys(objectPermission).reduce(
    (acc, current) => ({
      ...acc,
      [current]: objectPermission[current].some((r) =>
        authorities.some((p) => p === r || p === "ROLE_ADMIN")
      ),
    }),
    { _IS_INIT_PERMISSION: true }
  );
};

export const isEmail = (data) => {
  // var re = /^([a-zA-Z0-9]){4,}\@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9].[a-zA-Z]{2,}$/g;
  var re =
    /^([a-zA-Z0-9]+((\.|_)?[a-zA-Z0-9]+)*)+\@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}([a-zA-Z0-9].[a-zA-Z]{2,})+$/g;
  return re.test(data.toLowerCase());
};

export const getNhomDaoTao = () => {
  const { _PERMISSION = {}, nhomDaoTao } = getState().auth?.currentUser;
  return _PERMISSION.ROLE_ADMIN ? getState().global.nhomHienTai : nhomDaoTao;
};

export const getCurrentUserId = () => {
  const user = getState().auth?.currentUser;
  return user?.id;
}

export const getNameDate = (m) => {
  const d = moment(m).format("dddd");
  if (d === "Monday") return "Thứ 2";
  if (d === "Tuesday") return "Thứ 3";
  if (d === "Wednesday") return "Thứ 4";
  if (d === "Thursday") return "Thứ 5";
  if (d === "Friday") return "Thứ 6";
  if (d === "Monday") return "Thứ 7";
  return "Chủ nhật";
};

export const leadingZeros = (data) => {
  var result;
  if(Array.isArray(data)) {
    result = data.map((item)=> {
      if(Number(item) < 10) {
        return `0${item}`;
      }
      else {
        return `${item}`;
      }
    });
    return result;
  }
  else {
    return Number(data) < 10 ? `0${data}`:data;
  }
}

export function checkCustomShow() {
  const domain = window.location.origin;
  return [
    "https://qldt.bvxanhpon.vn",
    "https://qldt.bvdktinhthanhhoa.com.vn",
  ].includes(domain)
}
