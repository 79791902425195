import Footer from "@containers/Footer";
import Header from "@containers/Header";
import SideBar from "@containers/SideBar";
import Authorization from "@src/containers/Authorization";
import { Layout, Spin } from "antd";
import React from "react";
import { useEffect } from "react";
import Loadable from "react-loadable";
import { useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import "./style.scss";

function Loading() {
  return (
    <div style={{ paddingTop: 50, heigh: 400 }}>
      <Spin size="large">Loading</Spin>
    </div>
  );
}

var routes = [
  {
    roles: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
    path: "/manage/program",
    icon: "ni ni-book-bookmark text-blue",
    component: Loadable({
      loader: () => import("@manage/containers/program"),
      loading: Loading,
    }),
  },
  // {
  //   roles: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
  //   path: "/manage/program-formal",
  //   icon: "ni ni-book-bookmark text-blue",
  //   component: Loadable({
  //     loader: () => import("@manage/containers/program"),
  //     loading: Loading,
  //   }),
  // },
  // {
  //   roles: ["ROLE_ADMIN", "CTDT_R", "CTDT_CRUD", "BG_R", "BG_CRUD"],
  //   path: "/manage/program-mentoring",
  //   icon: "ni ni-book-bookmark text-blue",
  //   component: Loadable({
  //     loader: () => import("@manage/containers/program"),
  //     loading: Loading,
  //   }),
  // },
  {
    roles: [
      "ROLE_ADMIN",
      "KH_DK_R",
      "KH_DK_CRUD",
      "KH_DH_R",
      "KH_DH_CRUD",
      "KH_HT_R",
      "KH_H_R",
      "KH_H_D",
    ],
    path: "/manage/course",
    name: "Dự kiến",
    icon: "ni ni-air-baloon text-blue",
    component: Loadable({
      loader: () => import("@manage/containers/course"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "DD_R", "DD_CRUD", "CSDT_R", "CSDT_CRUD"],
    path: "/manage/category",
    name: "Danh mục",
    icon: "ni ni-archive-2 text-primary",
    component: Loadable({
      loader: () => import("@manage/containers/diaDiemDaoTao"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "XL_CRUD"],
    path: "/manage/make-schedule",
    component: Loadable({
      loader: () => import("@manage/containers/make-schedule"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN"],
    path: "/manage/points",
    component: Loadable({
      loader: () => import("@manage/containers/points"),
      loading: Loading,
    }),
  },
  {
    roles: [],
    path: "/manage/user-profile",
    component: Loadable({
      loader: () => import("@site/profile"),
      loading: Loading,
    }),
  },
  {
    roles: [],
    path: "/manage/notifications",
    component: Loadable({
      loader: () => import("@manage/containers/notifications"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN"],
    path: "/manage/results",
    component: Loadable({
      loader: () => import("@manage/containers/result"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "CTKH_R", "CTKH_IMPORT"],
    path: "/manage/info-course/:id",
    component: Loadable({
      loader: () => import("@manage/containers/info-course"),
      loading: Loading,
    }),
  },
  {
    roles: [
      "ROLE_ADMIN",
      "QLTK_R",
      "QLTK_CRUD",
      "QLTK_I",
      "QLTK_SV_CRUD",
      "QLTK_SV_R",
    ],
    path: "/manage/account",
    component: Loadable({
      loader: () => import("@manage/containers/user"),
      loading: Loading,
    }),
  },
  {
    roles: [
      "ROLE_ADMIN",
      "TTHC_CV_R",
      "TTHC_HH_R",
      "TTHC_HV_R",
      "TTHC_CV_CRUD",
      "TTHC_HH_CRUD",
      "TTHC_HV_CRUD",
    ],
    path: "/manage/administrative",
    component: Loadable({
      loader: () => import("@manage/containers/thongTinHanhChinh"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "NDT_R", "NDT_CRUD"],
    path: "/manage/training-group",
    component: Loadable({
      loader: () => import("@manage/containers/training"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/ho-so",
    component: Loadable({
      loader: () => import("@manage/containers/hoSo"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "NTK_R", "NTK_U", "NTK_CD"],
    path: "/manage/account-group",
    component: Loadable({
      loader: () => import("@manage/containers/group-account"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN"],
    path: "/manage/don-vi-ho-tro",
    component: Loadable({
      loader: () => import("@manage/containers/donViHoTro"),
      loading: Loading,
    }),
  },

  // route giảng viên
  {
    roles: ["ROLE_ADMIN", "ROLE_TEACHER"],
    path: "/manage/class",
    component: Loadable({
      loader: () => import("@manage/containers/class"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "ROLE_TEACHER", "ROLE_STUDENT"],
    path: "/manage/schedule",
    component: Loadable({
      loader: () => import("@manage/containers/schedule"),
      loading: Loading,
    }),
  },

  // route học viên
  {
    roles: ["ROLE_ADMIN", "DKKH_C"],
    path: "/manage/register-course",
    component: Loadable({
      loader: () => import("@manage/containers/register"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "DKKH_C"],
    path: "/manage/has-register",
    component: Loadable({
      loader: () => import("@manage/containers/list-register"),
      loading: Loading,
    }),
  },
  {
    roles: ["ROLE_ADMIN", "ROLE_TEACHER", "ROLE_STUDENT"],
    path: "/manage/schedule-student",
    component: Loadable({
      loader: () => import("@manage/containers/schedule"),
      loading: Loading,
    }),
  },
  {
    roles: [
      "ROLE_ADMIN",
      "ROLE_TEACHER",
      "ROLE_STUDENT",
      "TKB_CRUD",
      "ROLE_GV",
    ],
    path: "/manage/timetable",
    component: Loadable({
      loader: () => import("@manage/containers/timetable"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/result-student",
    component: Loadable({
      loader: () => import("@manage/containers/result"),
      loading: Loading,
    }),
  },
  {
    roles: [],
    path: "/manage/ket-qua-hoc-tap",
    component: Loadable({
      loader: () => import("@manage/containers/ketQua"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/teaching",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/teaching"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/course",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/course"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/student",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/student"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/money",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/money"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/schedule",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/schedule"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/student-money",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/studentMoney"),
      loading: Loading,
    }),
  },
  {
    path: "/manage/statistic/newCourse",
    component: Loadable({
      loader: () => import("@manage/containers/statistics/newCourse"),
      loading: Loading,
    }),
  },
];

const Admin = (props) => {
  const { countNoti } = useDispatch().notification;

  useEffect(() => {
    countNoti();
  }, []);
  
  return (
    <div>
      <SideBar
        {...props}
        logo={{
          innerLink: "/admin",
          imgSrc: require("@assets/img/brand/logo-isoft.png").default,
          imgAlt: "...",
        }}
      />
      <Layout style={{ overflow: "hidden" }}>
        <Header />
        <Switch>
          {routes.map((route, key) => {
            if (route.component) {
              return (
                <Authorization
                  exact
                  path={route.path}
                  key={key}
                  roles={route.roles}
                  render={(props, auth) => <route.component {...props} />}
                />
              );
            }
          })}
        </Switch>
        <Footer></Footer>
      </Layout>
    </div>
  );
};

export default Admin;
