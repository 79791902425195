import baseAccess from "./base-provider";
import constants from "../utils/const";
import clientUtils from "@src/utils/client-utils";
import api from "@src/utils/api";

export default {
  ...baseAccess({ url: constants.api.file }),
  uploadFile: (file, fileName) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);

      clientUtils
        .upload("post", constants.api.file + "/upload", formData)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
