import Icon, { LoadingOutlined } from "@ant-design/icons";
import React, { forwardRef } from "react";
import { Button as Bt } from "./style";

const Button = (
  {
    icon,
    content,
    iconSvg,
    className = "btn-pink",
    color = "pink",
    disabled,
    loading,
    onClick = () => {},
    ...props
  },
  ref
) => {
  return (
    <Bt
      ref={ref}
      className={`btn-${color} ${
        loading ? "custom-btn-loading" : disabled ? "custom-btn-disabled" : ""
      }`}
      type="button"
      disabled={disabled || loading}
      onClick={onClick}
      {...props}
    >
      <div className="custom-btn">
        <span className="custom-btn-content">{content}</span>
        {loading ? (
          <div className="custom-icon-loading">
            <LoadingOutlined />
          </div>
        ) : icon ? (
          <i className={`custom-btn-icon ${icon}`}></i>
        ) : iconSvg ? (
          <Icon className="custom-btn-icon" component={iconSvg} />
        ) : (
          <></>
        )}
      </div>
    </Bt>
  );
};

export default forwardRef(Button);
