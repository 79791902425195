import courseProvider from "@data-access/course";
import fileProvider from "@data-access/file";
import registerProvider from "@data-access/register-course";
import { validatePermission } from "@src/utils/common";

const mapPermission = {
  _UPLOAD_COURSE_FILE: ["CTKH_UPLOAD", "ROLE_ADMIN"],
  _IMPORT: ["CTKH_IMPORT", "ROLE_ADMIN"],
  _CRUD: ["CTKH_CRUD", "ROLE_ADMIN"],
};

export default {
  state: {
    courseInfo: {},
    registerInfo: {},
    listRegister: [],
    totalRegister: 0,
    listFile: [],
    listFileCourse: [],
    listFileRegister: [],
    fileName: "",
    fileDetail: null,

    // object chứa quyền theo tài khoản đăng nhập
    _PERMISSION: {},
    // các quyền theo logic hệ thống
    _authorities: {
      _UPLOAD: false,
    },
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
    updatePermission(state, payload = {}) {
      return {
        ...state,
        permission: { ...(state.permission || {}), ...payload },
      };
    },
  },
  effects: (dispatch) => ({
    getCourseInfo: (
      { id },
      { info_course: { _authorities }, register_course: { params } }
    ) => {
      courseProvider.getById(id).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.info_course.updateData({
            courseInfo: res.data,
            _authorities: {
              ..._authorities,
              _UPLOAD: res.data?.status === 1 || res.data?.status === 2,
            },
          });
          dispatch.global.updateData({
            nhomHienTai: res.data?.nhomDaoTao,
          });
          dispatch.register_course.updateData({
            params: { ...params, courseId: id },
          });
          dispatch.global.getRoleId({
            loaiTaiKhoan: 20,
            nhomHienTai: res.data?.nhomDaoTao,
          });
          if (res.data.fileIds && res.data.fileIds.length > 0) {
            fileProvider.search({ ids: res.data.fileIds }).then((s) => {
              if (s && s.code === 0 && s.data) {
                dispatch.info_course.updateData({ listFileCourse: s.data });
              }
            });
          }
        }
      });
    },
    getListFile: (ids, { info_course: { listFile } }) => {
      fileProvider
        .search({ ids: ids || listFile.map((item) => item.id) })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.info_course.updateData({
              listFile: res.data,
            });
          }
        });
    },
    getListRegister: (payload, state) => {
      registerProvider.search({ ...payload, size: 999 }).then((res) => {
        if (res && res.code === 0 && res.data) {
          dispatch.info_course.updateData({
            listRegister: res.data,
            totalRegister: res.totalElements,
          });
          dispatch.register_course.updateData({
            listData: res.data,
            totalRegister: res.totalElements,
          });
        }
      });
    },
    updateRegister: ({ data, newData }, { info_course: { listRegister } }) => {
      const newListRegister = Object.assign([], listRegister);
      const index = newListRegister.findIndex((item) => item.id === data.id);
      newListRegister[index] = { ...newListRegister[index], ...newData };

      dispatch.info_course.updateData({
        listRegister: newListRegister,
      });
    },
    enterPointTotal: (payload, { info_course: { listRegister } }) => {
      return new Promise((resolve, reject) =>
        registerProvider
          .putBatch(
            listRegister.map((item) => ({
              active: item.active,
              courseId: item.courseId,
              id: item.id,
              studentId: item.studentId,
              rollCall: item.rollCall,
              hasCertificate: item.hasCertificate,
              voucher: item.voucher,
              dinhMucId: item.dinhMucId,
              total: item.total ? parseFloat(item.total) : 0,
            }))
          )
          .then((res) => {
            if (res && res.code === 0) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((e) => reject(e))
      );
    },
    saveFileCourse: (
      payload,
      { info_course: { courseInfo, listFileCourse, fileName, file } }
    ) => {
      return new Promise((resolve, reject) => {
        const arrSplit = file.name.split(".");
        const type = arrSplit[1];
        const nameFile = `${fileName}.${type}`;
        fileProvider
          .uploadFile(file, nameFile)
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              const fileData = res.data;
              courseProvider
                .updateFile(
                  {
                    ...courseInfo,
                    nameNewFile: nameFile,
                    fileIds: [
                      ...listFileCourse
                        .filter((item) => item.id)
                        .map((item) => item.id),
                      fileData.id,
                    ],
                  },
                  courseInfo.id
                )
                .then((res) => {
                  if (res && res.code === 0)
                    dispatch.info_course.updateData({
                      file: "",
                      fileName: "",
                      listFileCourse: res.data,
                    });
                  resolve(res);
                });
            } else {
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deleteFileCourse: (
      fileData,
      { info_course: { courseInfo, listFileCourse } }
    ) => {
      return new Promise((resolve, reject) => {
        courseProvider
          .update(
            {
              ...courseInfo,
              fileIds: listFileCourse
                .filter((item) => item.id != fileData.id)
                .map((item) => item.id),
            },
            courseInfo.id
          )
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch.info_course.updateData({
                courseInfo: res.data,
                listFileCourse: listFileCourse.filter(
                  (item) => item.id != fileData.id
                ),
              });
            }

            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    updateFileName: (
      payload,
      { info_course: { courseInfo, fileDetail, fileName, listFileCourse } }
    ) => {
      return new Promise((resolve, reject) => {
        courseProvider
          .updateFileName(courseInfo?.id, { ...fileDetail, fileName })
          .then((res) => {
            if (res && res.code === 0) {
              const newListFileCourse = Object.assign([], listFileCourse);
              const index = newListFileCourse.findIndex(
                (item) => item.id === fileDetail.id
              );
              newListFileCourse[index] = res.data;
              dispatch.info_course.updateData({
                fileDetail: null,
                fileName: "",
                listFileCourse: newListFileCourse,
              });
            }
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },

    saveFileRegister: (
      payload,
      { info_course: { registerInfo, listFileRegister, fileName, file } }
    ) => {
      return new Promise((resolve, reject) => {
        const arrSplit = file.name.split(".");
        const type = arrSplit[1];
        const nameFile = `${fileName}.${type}`;
        fileProvider
          .uploadFile(file, nameFile)
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              const fileData = res.data;
              registerProvider
                .updateFile(
                  {
                    ...registerInfo,
                    nameNewFile: fileName,
                    fileIds: [
                      ...listFileRegister
                        .filter((item) => item.id)
                        .map((item) => item.id),
                      fileData.id,
                    ],
                  },
                  registerInfo.id
                )
                .then((res) => {
                  if (res && res.code === 0)
                    dispatch.info_course.updateData({
                      file: "",
                      fileName: "",
                      listFileRegister: res.data,
                    });
                  resolve(res);
                });
            } else {
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deleteFileRegister: (
      fileData,
      { info_course: { registerInfo, listFileRegister } }
    ) => {
      return new Promise((resolve, reject) => {
        registerProvider
          .patch(
            {
              fileIds: listFileRegister
                .filter((item) => item.id != fileData.id)
                .map((item) => item.id),
            },
            registerInfo.id
          )
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch.info_course.updateData({
                registerInfo: res.data,
                listFileRegister: listFileRegister.filter(
                  (item) => item.id != fileData.id
                ),
              });
            }

            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    updateFileNameRegister: (
      payload,
      { info_course: { registerInfo, fileDetail, fileName, listFileRegister } }
    ) => {
      return new Promise((resolve, reject) => {
        registerProvider
          .updateFileName(registerInfo?.id, {
            ...fileDetail,
            fileName,
          })
          .then((res) => {
            if (res && res.code === 0) {
              const newListFileRegister = Object.assign([], listFileRegister);
              const index = newListFileRegister.findIndex(
                (item) => item.id === fileDetail.id
              );
              newListFileRegister[index] = res.data;
              dispatch.info_course.updateData({
                fileDetail: null,
                fileName: "",
                listFileRegister: newListFileRegister,
              });
            }
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    validatePermission: (payload, { info_course: { _PERMISSION } }) => {
      if (!_PERMISSION?._IS_INIT_PERMISSION) {
        dispatch.info_course.updateData({
          _PERMISSION: validatePermission(mapPermission),
        });
      }
    },
  }),
};
