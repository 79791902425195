import fetchProvider from "@data-access/course";
import dinhMucHoTroProvider from "@data-access/dinh-muc-ho-tro-provider";
import { getNhomDaoTao } from "@src/utils/common";
import { toast } from "react-toastify";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider,
    storeName: "course",
    initStore: {
      // role theo tk đăng nhập
      _PERMISSION: {},
    },
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = {
          ...state.course.params,
          ...payload,
          nhomDaoTao: getNhomDaoTao(),
        };
        dispatch.course.updateData({
          params: newParams,
        });

        return new Promise((resolve, reject) => {
          fetchProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.course.updateData({
                  listData: res.data.map((item) => {
                    const map = {};
                    for (let i = 0; i < item.dsDinhMucHoTro?.length; i++) {
                      const dmht = item.dsDinhMucHoTro[i];
                      map[`dinhMucHoTro_${dmht.donViHoTro?.id}`] =
                        dmht.dinhMucHoTro;
                    }
                    return {
                      ...item,
                      value: item.id,
                      label: item.ten,
                      ...map,
                    };
                  }),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
      getData: (payload, state) => {
        fetchProvider.search(payload).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.course.updateData({
              listCourse: res.data,
              totalElements: res.totalElements,
            });
          }
        });
      },
      getCoursePlan: (payload, state) => {
        fetchProvider.search({ ...payload, status: 1 }).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.course.updateData({
              listCoursePlan: res.data,
              totalCoursePlan: res.totalElements,
            });
          }
        });
      },
      getCourseStudying: (payload, state) => {
        fetchProvider.search({ ...payload, status: 2 }).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.course.updateData({
              listCourseStudying: res.data,
              totalCourseStudying: res.totalElements,
            });
          }
        });
      },
      getDetail: ({ id }, state) => {
        fetchProvider.getById(id).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.course.updateData({
              courseInfo: res.data,
            });
          }
        });
      },
      getAll: ({nhomHienTai, programId="", tuNgay="", denNgay=""}) => {
        fetchProvider
          .search({
            active: true,
            page: 0,
            size: 999999,
            nhomDaoTao: nhomHienTai,
            programId,
            tuNgay,
            denNgay,
          })
          .then((res) => {
            if (res && res.code === 0 && res.data) {
              dispatch.course.updateData({
                listAllCourse: res.data,
              });
            }
          });
      },
      saveDinhMucHoTro: ({ data, courseId }, state) => {
        dinhMucHoTroProvider.saveAll(data, courseId).finally((e) => {
          dispatch.course.search();
        });
      },
      save: ({ ignoreSearch, ...payload }) => {
        const callApi = payload.id ? fetchProvider.put : fetchProvider.post;
        return new Promise((resolve, reject) => {
          callApi(payload, payload.id)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                if (!ignoreSearch) dispatch.course.search();
              } else if (res && res.code === 401) {
                window.location.href = "/login";
              } else {
                toast.error(res.message);
              }
              resolve(res);
            })
            .catch((err) => reject(err));
        });
      },
    }),
  }),
};
