import baiGiangProvider from "@data-access/bai-giang-provider";
import baseStore from "../base-store";

export default {
  ...baseStore({
    fetchProvider: baiGiangProvider,
    storeName: "baiGiang",
    initStore: {
      listAllProgram: [],
      // role theo tk đăng nhập
      _PERMISSION: {},
    },
  }),
};
