import fetchProvider from "@data-access/program";
import { getNhomDaoTao, validatePermission } from "@src/utils/common";
import baseStore from "../base-store";
import fileProvider from "@data-access/file";

const mapPermission = {
  _VISIBLE_PROGRAM: ["CTDT_R", "CTDT_CRUD"],
  _VISIBLE_SUBJECT: ["BG_R", "BG_CRUD"],
  _EDIT_SUBJECT: ["BG_CRUD"],
  _PROGRAM_CRUD: ["CTDT_CRUD"],
};

export default {
  ...baseStore({
    fetchProvider: fetchProvider,
    storeName: "program",
    initStore: {
      programInfo: {},
      listAllProgram: [],
      listDataSelect: [],
      // role theo tk đăng nhập
      _PERMISSION: {},
      listProgramFile: [],
      fileName: "",
      fileDetail: null,
    },
    customEffect: ({ dispatch }) => ({
      search: (payload = {}, state) => {
        const newParams = {
          ...state.program.params,
          ...payload,
          nhomDaoTao: getNhomDaoTao(),
        };
        dispatch.program.updateData({
          params: newParams,
        });

        return new Promise((resolve, reject) => {
          fetchProvider
            .search(newParams)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.program.updateData({
                  listData: res.data,
                  listDataSelect: res.data.map((item) => ({
                    label: item.ten,
                    value: item.id,
                  })),
                  totalElements: res.totalElements,
                });
              }
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
      getData: (payload, state) => {
        fetchProvider.search(payload).then((res) => {
          if (res && res.code === 0 && res.data) {
            dispatch.program.updateData({
              listData: res.data,
              totalElements: res.totalElements,
            });
          }
        });
      },
      saveFileProgram: (
        payload,
        {
          // pro: { courseInfo, listProgramFileCourse, fileName, file },
          program: {listProgramFile, programInfo, fileName, file },
        }
      ) => {
        return new Promise((resolve, reject) => {
          const arrSplit = file?.name.split(".");
          const type = arrSplit[1];
          const nameFile = `${fileName}.${type}`;
          fileProvider
            .uploadFile(file, nameFile)
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                const fileData = res.data;
                fetchProvider
                  .updateFile(
                    {
                      ...programInfo,
                      fileIds: [
                        ...listProgramFile
                          .filter((item) => item.id)
                          .map((item) => item.id),
                        fileData.id,
                      ],
                    },
                    programInfo.id
                  )
                  .then((res) => {
                    if (res && res.code === 0)
                      dispatch.program.updateData({
                        file: "",
                        fileName: "",
                        listProgramFile: res.data,
                      });
                    resolve(res);
                  });
              } else {
                resolve(res);
              }
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      deleteFileProgram: (
        fileData,
        { program: { programInfo, listProgramFile} }
      ) => {
        return new Promise((resolve, reject) => {
          fetchProvider
            .put(
              {
                ...programInfo,
                fileIds: listProgramFile
                  .filter((item) => item.id != fileData.id)
                  .map((item) => item.id),
              },
              programInfo.id
            )
            .then((res) => {
              if (res && res.code === 0 && res.data) {
                dispatch.program.updateData({
                  programInfo: res.data,
                  listProgramFile: listProgramFile.filter(
                    (item) => item.id != fileData.id
                  ),
                });
              }
  
              resolve(res);
            })
            .catch((e) => reject(e));
        });
      },
      validatePermission: (payload, { program: { _PERMISSION } }) => {
        if (!_PERMISSION?._IS_INIT_PERMISSION) {
          dispatch.program.updateData({
            _PERMISSION: validatePermission(mapPermission),
          });
        }
      },
    }),
  }),
};
